//@import "swsn-variables.less";

@import "swsn-buttons.less";
@import "swsn-popovers.less";
@import "swsn-common.less";

@import "swsn-interessenten-account.less";


/* breakpoint styles */

@import "swsn-breakpoints.less";

@import "swsn-core-update-styles.less";


/* fix blur */
.details-label-branch-icon {
  height: auto !important;
}

.input-required-information {
  padding-left: 7px;
  font-size: 12px;
}