@screen-sm-max: 991px; // = bootstrap screen-md-min -1
@screen-xs-max: 767px; // = bootstrap screen-sm-min -1

@screen-sm-max-800: 800px;
@screen-xs-max-359: 359px;

@media (max-width: @screen-sm-max) {

    body.poco-cockpit.side-collapse div#cage > #main {
        clear: both;
        width: 100%;
    }
}
@media (max-width: 867px){
    .barzahlen-quad-table .display-table:first-child .display-table-row .display-table-cell {
        border-bottom-width: 15px;
    }
    .barzahlen-quad-table .display-table {
        display:block;
        background-color: #FFF;
        .display-table-row {
            display: block;
            .display-table-cell {
                display: block;
                width:100%;
                background-color: #FFF;
                border:none;
            }
        }
    }
}

@media (max-width: @screen-xs-max) {

    body.poco-cockpit.side-collapse div#cage {
        width: 100% !important;
    }

    div#branches {
        margin-right: 14px;
    }

    div#menu-bg-line {
        top: -61px;
    }

    /*** NEU ***/
    div#headMenu {
        /*top: 55px;*/
    }
    div#topMenu {
        /*top: 103px;*/
    }
    /*** NEU ***/   

    div#topMenu .container-fluid {
        padding-left: 15px;
    }

    ul#top-drop-menu {
        background-color: #ffffff;
        margin-top: 0px;
    }

    .navbar-toggle {
        margin: 5px 15px 7px 15px;
        padding: 10px 10px;
        border: 2px solid;
    }

    #topMenu .navbar-nav > li > a.dropdown-toggle {
        padding-left: 31px;
    }

    #topMenu .open .dropdown-menu > li.sub-menu-head {
        padding-left: 31px;
        &:hover {
            padding-left: 25px;
        }
    }

    #topMenu .open .dropdown-menu li {
        &:hover, &:focus {
            background-color: #f3f4f6 !important;
            border-left: 6px solid #ff8a16 !important;
            padding-left: 7px;

            a {
                padding-left: 12px;
            }
        }

        &:last-child {
            border-bottom: 1px solid #96a6b3;
        }	
    }

    #topMenu .open .dropdown-menu li a {
        padding-left: 31px;		
    }

    .single-button {
        .pull-right {
            width: 100%;

            .btn-primary {
                width: 100%;
            }
        }		
    }

    #process-layout-container {
        padding: 0px 15px;
        margin-top: -5px;
    }

    #close-process {
        right: 15px;
    }	

    .form-group-panel-default .form-group .key-label img.label-icon {
        margin-bottom: 7px;
        margin-left: 0px;
        width: 50px;
    }
	
	
	#process-layout-container.with-marginal-column {
		width: 100%;
		
		#close-process {
			right: 15px;
		}
		
		.panel.panel-default.visible-xs {
			margin-bottom: 0px;
			border-bottom: 0px;
			
			.panel-heading {
				border-bottom: 0px;
			}
		}

		#process-fluid-panel {
			float: none;
			
			.panel.panel-default {
				margin-right: 0px;
			}		
		}

		#marginal-column {
			float: none;
			width: 100%;
			margin-left: 0px;
			
			.panel-heading {
				background-color: #F9F9F9;
				border-bottom: 0px;
				
				h3 {
					font-size: 18px;
				}	
			}
		}	
	}
}

@media (max-width: @screen-sm-max-800) {

    body.side-collapse {	
        #cage {
            width: 100% !important;
        }
    }	
}	

@media (max-width: @screen-xs-max-359) {

    #process-layout-container {
        padding: 0px 10px;
        margin-top: -10px;
    }

    #close-process {
        right: 10px;
    }	

    .form-group-panel-default .form-group .key-label img.label-icon {
        width: 35px;
    }
	
	#process-layout-container.with-marginal-column #close-process {
		right: 10px;
	}	
}


.open > .dropdown-menu {
    max-height: 250px;
    overflow-y: auto;
}