@import "../../../resources/css/de_DE/bootstrap.css";
@import "../../../resources/css/de_DE/bootstrap.datepicker.css";
@import "../../../resources/css/de_DE/bootstrap.select.css";
@import "../../../resources/css/de_DE/bootstrap.dataTables.css";
@import "../../../resources/css/de_DE/bootstrap.fileinput.css";
@import (inline) "../../css/de_DE/popover-extra-placements.css";
@import "../../../resources/css/de_DE/bootstrap.slider.css";
@import "../../../resources/css/de_DE/idangerous.swiper.css";
@import "../../../resources/css/de_DE/autocomplete.css";
//@import (inline) "../../css/de_DE/contractStatus.css";

@import (inline) "../../css/de_DE/itc-styles.css";
@import "poco/poco-styles.less";
//@import (inline) "../../css/de_DE/client-styles.css";

@import "swsn-styles.less";


