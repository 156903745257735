.popover {
  border-color: #7f8080;
}
.popover.topLeft .arrow{
  border-top-color: #7f8080;
}
.popover.topRight .arrow{
  border-top-color: #7f8080;
}
.popover.rightTop .arrow{
  border-right-color: #7f8080;
}
.popover.rightBottom .arrow{
  border-right-color: #7f8080;
}
.popover.bottomLeft .arrow{
  border-bottom-color: #7f8080;
}
.popover.bottomRight .arrow{
  border-bottom-color: #7f8080;
}
.popover.leftTop .arrow{
  border-left-color: #7f8080;
}
.popover.leftBottom .arrow{
 border-left-color: #7f8080;
}