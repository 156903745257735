
body.poco-cockpit {
	div#cage {
		width: 100%;
		
		> #main {
			padding-left: 0px;
			padding-right: 0px;
			clear: both;	
			text-align: center;
			width: 100%;
		}
	}
}

body.poco-cockpit.in {
	div#menu-bg-line {
		height: 214px !important;
	}
}

body.poco-cockpit.side-collapse {
	div#cage {
		> #accordionMenu {
			padding-top: 10px;
			margin-left: 15px;
			margin-right: -15px;
			padding-right: 20px;
		}
		> #main {
			width: 75%;
			clear: none;
		}	
	}
}

div#menu-bg-line {
	top: -2px;
}

/*** NEW ***/
#menu-container {
  height: 118px;
  display: none;  
}  

div#headMenu {
  /*position: absolute;
  top: 114px;
  left: 0px;
  width: 100%;
  z-index: 11112;*/  
}
div#headMenu.fixed {
  /*position: fixed;
  top: 0px;*/
}  
/*** NEW ***/

div#headMenu {
	height: 48px;
	background-color: #f3f4f6;
	border-top: 1px solid #96a6b3;
	
	> div {
		margin-top: 12px;
	}
	
	#logout, #home-link {
		color: #1a3552;
		&:hover {
			text-decoration: none;
		}
		
		#head-menu-logout-icon {
			margin-left: 20px;
		}
	}
		
	.no-news {
		margin-right: 15px;
	}	
	
	.postbox-badge {
		background-color: #ff8a16;
		margin-left: 0px;
		margin-right: 15px;
		margin-top: -8px;
		padding: 1px 6px 3px 5px;
	}	
}

div#topMenu {
	.container-fluid {
		padding-left: 0px;
		
		> #itc-navbar-collapse {
			padding-left: 16px;
		}
	}
	
	#miniature-context-3-lines {
		font-size: 12px;
		color: #ffffff;
		line-height: 13px;
		margin-right: 15px;
		padding-top: 4px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	
	#miniature-context-2-lines {
		font-size: 12px;
		color: #ffffff;
		line-height: 20px;
		margin-right: 15px;
		padding-top: 4px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}		
}	

/* NEW */
div#topMenu {
   /* position: absolute;
    top: 162px;
    left: 0px;
    width: 100%;
    background-color: #5e7386;
    border-bottom: 2px solid #ff8a16;
    
    .navbar {
      margin-bottom: 0px;
      min-height: 48px;
    }  */
}
div#topMenu.fixed-with-headmenu {
/*  position: fixed;
  top: 48px;  */
}
/* NEW */ 	

#widget-layout-container {
	margin: 0 auto;
	min-width: 320px;
	max-width: 1565px; /*Standard Poco = höchstens 5 Widgets
	--- Wichtig: Dieser Wert muss im Projekt and die tatsächliche Widget-Größe angepasst werden.
	--- Padding left & right von Widget-layout-Container sind ebenfalls in diesem Wert dazu zu addieren.  
	 */
	padding-left: 0px;
	padding-right: 0px;
}

.widget-container { /* ausgeloggt:  breit: 315px, hoch 335px */
	background-color: #FFFFFF;
	border: 1px solid #96a6b3;
	float: left;
	height: 313px;
	margin: 10px;
	position: relative;
	text-align: left;
	width: 293px;
	
	.panel {
		background-color: transparent;
	}		
}

.in .widget-container { /* eingeloggt:  breit: 315px, hoch 315px */
	height: 293px;
}
.widget-loading-container {
	text-align: center;
	
	img {
	 margin-top: 25px;	
	} 
}	

#login-key {
	margin-top: 12px;
	width: 109%;
}
.widget-container.objectdataWidget{
    background: url(../../../ems/images/de_DE/svg/icon-highlight-bg-objectdata.svg) #FFFFFF no-repeat;
    background-size: 46%; 
    background-position: center 75px;
}

.widget-container.customer {
	background: url(../../images/de_DE/svg/icon-highlight-bg-customer.svg) #FFFFFF no-repeat;
	background-size: 43%;
    background-position: center 70px;
}

.widget-container.contract {
	background: url(../../images/de_DE/svg/icon-highlight-bg-contract.svg) #FFFFFF no-repeat;
	background-size: 46%;
    background-position: center 75px;
	
	img.branch-icon {
		height: 15px;
		vertical-align: top;
	}
	img.branch-icon.power {
		margin-top: 4px;
		margin-left: -4px;
		margin-right: -2px;
	}
	img.branch-icon.gas {
		margin-top: 3px;
		margin-left: -3px;
	}
	img.branch-icon.water {
		margin-top: 4px;
		margin-left: -1px;
		margin-right: 3px
	}
	img.branch-icon.thermal {
		margin-top: 4px;
		margin-left: -1px;
		margin-right: 3px
	}
}

.widget-container.meter.power {
	background: url(../../images/de_DE/svg/icon-highlight-bg-branch-energy.svg) #FFFFFF no-repeat;
	background-size: 25%;
  background-position: center 124px;
}
.widget-container.meter.gas {
	background: url(../../images/de_DE/svg/icon-highlight-bg-branch-gas.svg) #FFFFFF no-repeat;
	background-size: 30%;
  background-position: center 115px;
}
.widget-container.meter.water {
	background: url(../../images/de_DE/svg/icon-highlight-bg-branch-water.svg) #FFFFFF no-repeat;
	background-size: 53%;
  background-position: center 125px;
}
.widget-container.meter.thermal {
	background: url(../../images/de_DE/svg/icon-highlight-bg-branch-heating.svg) #FFFFFF no-repeat;
	background-size: 50%;
  background-position: center 115px;
}

.widget-container.quick-links {
	background: url(../../images/de_DE/svg/icon-highlight-bg-direct-link.svg) #FFFFFF no-repeat;
	background-size: 59%;
  background-position: center 90px;
}

.widget-container.prepayment {
	background: url(../../images/de_DE/svg/icon-highlight-bg-prepayment.svg) #FFFFFF no-repeat;
	background-size: 70%;
  background-position: center 105px;
}

.widget-container.invoice {
	background: url(../../images/de_DE/svg/icon-highlight-bg-invoice.svg) #FFFFFF no-repeat;
	background-size: 46%;
  background-position: center 75px;
}

.widget-container.customer-account {
	background: url(../../images/de_DE/svg/icon-highlight-bg-calculator.svg) #FFFFFF no-repeat;
	background-size: 52%;
  background-position: center 75px;
}

.widget-container.bankdata {
	background: url(../../images/de_DE/svg/icon-highlight-bg-bankdata.svg) #FFFFFF no-repeat;
	background-size: 46%;
  background-position: center 75px;
}

.widget-container.order-status {
  background: url(../../images/de_DE/svg/icon-highlight-bg-service-status.svg) #FFFFFF no-repeat;
  background-size: 50%;
  background-position: center 85px;  
}

.widget-container.energy-saving {
  background: url(../../images/de_DE/svg/icon-highlight-bg-consumption.svg) #FFFFFF no-repeat;
  background-size: 66%;
  background-position: 35px 70px;  
}
.widget-container.provideInformation {
  background: url(../../images/de_DE/svg/icon-highlight-bg-selbstauskunft.svg) #FFFFFF no-repeat;
  background-size: 60%;
  background-position: 60px 70px;  
}
.widget-container.data-protection {
  background: url(../../images/de_DE/svg/icon-highlight-strong-data-protection.svg) #FFFFFF no-repeat;
  background-size: 30%;
  background-position: center 120px; 
}
.widget-container.house-connection {
  background: url(../../images/de_DE/svg/icon-highlight-strong-house-connection.svg) #FFFFFF no-repeat;
  background-size: 42%;
  background-position: center 124px; 
}

.widget-container.postbox {
	background: url(../../images/de_DE/svg/icon-highlight-bg-postbox.svg) #FFFFFF no-repeat;
	background-size: 60%;
	background-position: center 118px;
	
	.postbox-entry {
		clear: both;
		float: left;
		
		&:last-child {
			margin-bottom: -1px;
		}
		
		a.widget-postbox-link {
			color: #EA7000;
			display: block;
			font-family: @std-font-family-bold;
			height: 33px;

			.widget-postbox-link-icon {
				font-family: @glyphicon-font;
				float: left;
				font-size: 13px;
				margin-left: 5px;
				margin-right: 5px;
				margin-top: 2px;	
			}
				
			.widget-postbox-subject {
				width: 175px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				float: left;
			}	
		}
		
	}
	
	.postbox-entry.read a.widget-postbox-link {
		font-family: @std-font-family;
		
		.widget-postbox-link-icon {
			color: #FFC78F;
		}	
	}	
}

.widget-formline-text {
	a {
		color: #ea7000;
	}	
}

.widget-bottom-container {
	position: absolute;
	left: 0px;
	bottom: 15px;
	
	a {
		color: #ea7000;
		background: url(../../images/de_DE/icon-link.png) 0px 3px no-repeat;
		padding-left: 23px;		
	}
	
	a.inactive {
		color: #9fabb7;
		cursor: default;
		background: url(../../images/de_DE/icon-link-inactive.png) 0px 3px no-repeat;	
		&:hover {
			text-decoration: none;
		}
		
		span.popover-tooltip {
			display: inline-block;
			margin-top: -2px;
		}
	}
	
	div:last-child {
		margin-bottom: 0px;
	}	
}

#quick-links {
	list-style-type: none;
	margin-left: -55px;
	li {
		background: url(../../images/de_DE/icon-link.png) 0px 3px no-repeat;
		padding-left: 23px;
		margin-bottom: 10px;
		
		a {
			color: #ea7000;
		}
	}	
}

.multi-widget {
	span.content-switch {
		display: table;
		
		span.content-switch-row {
			display: table-row;
			
			span.content-switch-cell {
				display: table-cell;
				
				span.content-switch-prev {
					color: @std-color;
					cursor: pointer;
					font-size: 11px;
					margin-right: 0px;
					padding: 5px 10px 5px 3px;
				}
				
				span.current-switch {
					color: @std-color;
				}
				
				span.total-switch {
					color: #9FABB7;
					&:before {
						content: '/';
					}					
				}
				
				span.content-switch-next {
					color: @std-color;
					cursor: pointer;
					font-size: 11px;
					margin-left: 0px;
					margin-right: 0px;
					padding: 5px 10px 5px 3px;
				}
			}
		}
	}
}

.widget-container.product-calculator {
  
  div.widget-consumption-input-row {
    clear: both;
    float: left;
    width: 100%;
    margin-top: 2px;
  }  
  
  div#power-slider-legend,
  div#power-v2-slider-legend,
  div#water-v2-slider-legend,
  div#power-v3-slider-legend,
  div#water-v3-slider-legend {
    p.widget-formline-text {
      clear: both;
      float: left;
      margin-top: -6px;
    }
    div.widget-formline-text {
      clear: both;
      float: left;
      margin-top: -5px;
      margin-bottom: -5px;
      width: 100%;
      div {
        float: left;
        width: 20%;
      }
      #power-slider-full-step-1,
	  #power-v2-slider-full-step-1,
	  #water-v2-slider-full-step-1,
	  #power-v3-slider-full-step-1,
	  #water-v3-slider-full-step-1 {
        padding-left: 5px;
      }
      #power-slider-full-step-2,
      #power-v2-slider-full-step-2,
      #water-v2-slider-full-step-2,
	  #power-v3-slider-full-step-2,
	  #water-v3-slider-full-step-2 {
        padding-left: 13px;
      }
      #power-slider-full-step-3,
	  #power-v2-slider-full-step-3,
	  #water-v2-slider-full-step-3,
	  #power-v3-slider-full-step-3,
	  #water-v3-slider-full-step-3 {
        padding-left: 21px;
      } 
      #power-slider-full-step-4,
	  #power-v2-slider-full-step-4,
	  #water-v2-slider-full-step-4,
	  #power-v3-slider-full-step-4,
	  #water-v3-slider-full-step-4 {
        padding-left: 29px;
      }
      #power-slider-full-step-5,
	  #power-v2-slider-full-step-5,
      #water-v2-slider-full-step-5,
	  #power-v3-slider-full-step-5,
	  #water-v3-slider-full-step-5 {
        padding-left: 35px;
      }         
    } 
  }
  
  div#power-v2-slider-legend,
  div#water-v2-slider-legend,
  div#power-v3-slider-legend,
  div#water-v3-slider-legend {
	margin-top: 14px;	
  }
  .widget-slider-container.power-v2,
  .widget-slider-container.water-v2,
  .widget-slider-container.power-v3,
  .widget-slider-container.water-v3 {
		margin-top: -2px;
	}		
  
  div#gas-slider-legend,
  div#gas-v2-slider-legend,
  div#gas-v3-slider-legend {
    #gas-slider-full-step-1,
	#gas-v2-slider-full-step-1,
	#gas-v3-slider-full-step-1 {
      background-image: url(../../images/de_DE/icon-productSlider-gas-cooking.png);
      background-repeat: no-repeat;
      background-position: 2px 14px;
      float: left;
      height: 36px;
      width: 25%;
      font-size: 12px;
      padding-top: 30px;
      padding-left: 0px;  
    }
    #gas-slider-full-step-2,
	#gas-v2-slider-full-step-2,
    #gas-v3-slider-full-step-2 {
      background-image: url(../../images/de_DE/icon-productSlider-gas-house-small.png);
      background-repeat: no-repeat;
      background-position: 5px 1px;
      float: left;
      height: 30px;
      width: 25%;
      font-size: 12px;
      padding-top: 30px;
      padding-left: 12px;    
    } 
    #gas-slider-full-step-3,
    #gas-v2-slider-full-step-3,
    #gas-v3-slider-full-step-3 {
      background-image: url(../../images/de_DE/icon-productSlider-gas-house-medium.png);
      background-repeat: no-repeat;
      background-position: 20px 1px;
      float: left;
      height: 30px;
      width: 25%;
      font-size: 12px;
      padding-top: 30px;
      padding-left: 22px;    
    }
    #gas-slider-full-step-4,
	#gas-v2-slider-full-step-4,
	#gas-v3-slider-full-step-4 {
      background-image: url(../../images/de_DE/icon-productSlider-gas-house-big.png);
      background-repeat: no-repeat;
      background-position: 26px 0px;
      float: left;
      height: 30px;
      width: 25%;
      font-size: 12px;
      padding-top: 30px;
      padding-left: 26px;      
    }
  }
  
  div#gas-v2-slider-legend,
  div#gas-v3-slider-legend {
	margin-top: 15px;
  }
  
  div.widget-slider-container {
    clear: both;
    float: left;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
  div.widget-slider-container.power {
    margin-top: -3px;
  }         
}

.double-widget-container {
  width: 293px;
  height: 313px;
  position: relative;
  float: left;
  margin: 10px;
  
  .double-widget-top {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    border: 1px solid #96a6b3;
    text-align: left;
    max-height: 147px;
    overflow: hidden;
   }
   
   .double-widget-bottom {
     position: absolute;
     bottom: 0px;
     left: 0px;
     width: 100%;
     border: 1px solid #96a6b3;
     text-align: left;
     max-height: 147px;
     overflow: hidden;
    } 
    
    .double-widget-top, .double-widget-bottom {
      
      .panel-body {
        
        height: 105px;
        
        .double-widget-link-container {
          position: absolute;
          left: 15px;
          bottom: 14px;
        }  
      }  
    } 
}  

#process-layout-container {
	margin: 0 auto;
	text-align: left;
	min-width: 320px;
	max-width: 960px;
	position: relative;
	padding: 0px 20px;
	
	#stepsContainer {
		margin-top: 0px;
		margin-right: 40px;
		position: absolute;
		top: 0px;
		right: 0px;
	}
}


span.step {
	background-color: #96a6b3;
}

span.step.active {
	background-color: #ff8a16; 
}

#close-process {
	position: absolute;
	top: 0px;
	right: 20px;
	z-index: 555;
			
	a.btn {
		font-family: @glyphicon-font;
		padding: 8px 12px;
	}
	a.btn-default {
		border-color: #9fabb7;
		&:hover {
			border-color: @std-color;
		}
	}		
}
#close-process.shadowed {
	
	-webkit-box-shadow: -1px 2px 6px 0px rgba(72, 95, 115, 0.4);
	-moz-box-shadow: -1px 2px 6px 0px rgba(72, 95, 115, 0.4);
	 box-shadow: -1px 2px 6px 0px rgba(72, 95, 115, 0.4);
}	 

.consumption-segments-container {
	margin-top: 5px;
	position: relative;
		
	> .consumption-segment {
		height: 34px;
	}
	> .consumption-segment.flatmate {
		background: url(../../images/de_DE/svg/icon-single-person.svg) transparent no-repeat center center;
		background-size: 36px;
		position: absolute;
		top: 0px;
		left: 0px;
		width: 50px;
	}
	> .consumption-segment.house-small {
		background: url(../../images/de_DE/svg/icon-house-small.svg) transparent no-repeat center center;
		background-size: 33px;
		position: absolute;
    top: 0px;
    left: 0px;
    width: 50px;
	}	
	> .consumption-segment.flatmates {
		background: url(../../images/de_DE/svg/icon-multiple-persons.svg) transparent no-repeat right center;
		background-size: 35px;
		position: absolute;
    top: 0px;
    right: 22px;
    width: 60px;
	}
	> .consumption-segment.house-large {
		background: url(../../images/de_DE/svg/icon-house-large.svg) transparent no-repeat right center;
		background-size: 31px;
		position: absolute;
    top: 0px;
    right: 22px;
    width: 60px;
	}
}

.consumption-segments-container .col-xs-20.col-xs-offset-2:after {
  background: url(../../images/de_DE/svg/icon-multiple-persons.svg) transparent no-repeat right center;
  background-size: 35px;
  height: 34px;  
}

.slider-track, .slider-selection {
  box-shadow: none;
  background-color: #e0e4e8;
}

.details-meter-value-container {

	margin-bottom: 10px;

	*.col-xs-24,
	*.col-xs-23,
	*.col-xs-22,
	*.col-xs-21,
	*.col-xs-20,
	*.col-xs-19,
	*.col-xs-18,
	*.col-xs-17,
	*.col-xs-16,
	*.col-xs-15,
	*.col-xs-14,
	*.col-xs-13,
	*.col-xs-12,
	*.col-xs-11,
	*.col-xs-10,
	*.col-xs-9,
	*.col-xs-8,
	*.col-xs-7,
	*.col-xs-6,
	*.col-xs-5,
	*.col-xs-4,
	*.col-xs-3,
	*.col-xs-2,
	*.col-xs-1  {
		padding: 3px 0px !important;
	}
	
	.mv-pre-comma {
		margin-top: -3px;
	}
	.mv-pre-comma.not-available {
		margin-top: -3px;
	}
	.mv-comma {
		margin-top: -3px;
	}
	.mv-post-comma {
		margin-top: -3px;
	}
}

.invoice-pdf-link, .invoice-pdf-link:hover, .invoice-pdf-link:focus  {
	color: #ea7000;
}

.invoice-pdf-icon {
	margin-top: -4px;
	margin-right: 7px;
}

.code-red {
	color: #FF0000 !important;	
}
.code-green {
	color: #32a817 !important;	
}
.code-blue {
  color: #0a6aa0 !important;  
}

.swiper-container, .swiper-slide {
  width: 291px;
  height: 251px;
}

.swiper-slide {
  position: relative;   
}

.swiper-slide.meter.power {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-energy.svg) #FFFFFF no-repeat;
  background-size: 25%;
    background-position: center 84px;
}
.swiper-slide.meter.gas {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-gas.svg) #FFFFFF no-repeat;
  background-size: 30%;
    background-position: center 95px;
}
.swiper-slide.meter.water {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-water.svg) #FFFFFF no-repeat;
  background-size: 53%;
    background-position: center 105px;
}
.swiper-slide.meter.thermal {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-heating.svg) #FFFFFF no-repeat;
  background-size: 50%;
    background-position: center 95px;
}



.btn-tooltip {
  float: right;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 5px;
}

.btn-inactive {
  background: none #BBBBBB;
  color: #FFFFFF; 
  cursor: default;
  &:hover, &:focus {
    background: none #BBBBBB; 
    color: #FFFFFF;   
  }
}

.navbar-triangle {
  color: #fff;
  font-size: 16px;
}  

#accordionMenu a[data-toggle=collapse] span.using-glyphicons {
  color: #fff;
}

.full-width-checkbox-text, .full-width-radio-text {
  label {
    float: none;
    margin-left: 35px;
  }
}




body.poco-cockpit:not(.in) {
	#topMenu {
		#itc-navbar-collapse {
			margin-left: -16px;
			margin-right: -15px;
			ul li.dropdown a {
				display: inline-block;
				padding-top: 13px;
				&:hover, &:focus {
					background-color: #95a3b1;
					height: 46px;
				}
			}	
		}
		#itc-navbar-collapse ul li.dropdown a.no-toggle {
			&:hover, &:focus {
				background-color: #5e7386;
			}
		}
		#itc-navbar-collapse ul li.dropdown a#logout {
			padding-left: 20px;
			padding-right: 20px;
			padding-top: 11px;
			.using-glyphicons {
				color: #FFFFFF;
			}
		}
	}
} 
