/* ITC */
@font-face {
  font-family: 'PT-Sans';
  src: url('../../fonts/PT-Sans.eot');
  src: url('../../fonts/PT-Sans.eot?#iefix') format('embedded-opentype'), url('../../fonts/PT-Sans.woff') format('woff'), url('../../fonts/PT-Sans.ttf') format('truetype'), url('../../fonts/PT-Sans.svg#PTSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'PT-Sans-bold';
  src: url('../../fonts/PT-Sans-bold.eot');
  src: url('../../fonts/PT-Sans-bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/PT-Sans-bold.woff') format('woff'), url('../../fonts/PT-Sans-bold.ttf') format('truetype'), url('../../fonts/PT-Sans-bold.svg#PTSansBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
html {
  position: relative;
  min-height: 100%;
}
body {
  background-image: url(../../images/de_DE/itc-background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  color: #485f73;
  font-family: 'PT-Sans';
  font-size: 16px;
  position: absolute;
  min-height: 100%;
  width: 100%;
}
#browser-notification {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: #FF1110;
  padding: 5px 25px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  width: 100%;
}
#cage,
footer ul {
  margin: 0px auto;
  width: 85%;
}
.using-glyphicons {
  color: #485f73;
  font-family: 'Glyphicons Halflings';
}
.prettyradio,
.prettycheckbox {
  margin-left: -25px;
}
.font-normal {
  font-family: 'PT-Sans' !important;
  font-weight: normal !important;
}
.font-bold {
  font-family: 'PT-Sans-bold';
  font-weight: normal;
}
.font-italic {
  font-style: italic;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
.cursor {
  cursor: pointer;
}
.no-paddings {
  padding: 0px;
}
.borderless {
  border: 0px !important;
}
a.form-link {
  color: #ea7000;
  background: url(../../images/de_DE/icon-link.png) 0px 3px no-repeat;
  padding-left: 23px;
}
a.form-link.inactive {
  color: #9fabb7;
  cursor: default;
  background: url(../../images/de_DE/icon-link-inactive.png) 0px 3px no-repeat;
}
a.form-link.inactive:hover {
  text-decoration: none;
}
a.std-text-link {
  color: #ea7000;
}
header {
  background: #fff;
  height: 114px;
}
img#logo {
  float: left;
  margin-left: 15px;
  margin-top: 23px;
}
div#branches {
  background: url(../../images/de_DE/svg/branches.svg) no-repeat top left / 100%;
  float: right;
  height: 69px;
  margin-right: 14px;
  margin-top: 23px;
  width: 308px;
}
#topMenu {
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
}
#topMenu.fixed {
  position: fixed;
  background-color: #5e7386;
  border-bottom: 2px solid  #ff8a16;
  height: 48px;
}
#topMenu.fixed-with-headmenu {
  position: fixed;
  background-color: #5e7386;
  border-bottom: 2px solid  #ff8a16;
  height: 50px;
  top: 48px;
}
#headMenu.fixed {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 11111;
}
span.product-branch-icon {
  display: inline-block;
  float: left;
  height: 20px;
  margin-right: 4px;
  width: 20px;
}
span.product-branch-icon.power {
  background: url(../../images/de_DE/svg/icon-power.svg) no-repeat center center / 100%;
}
span.product-branch-icon.gas {
  background: url(../../images/de_DE/svg/icon-gas.svg) no-repeat center center / 100%;
}
span.product-branch-icon.water {
  background: url(../../images/de_DE/svg/icon-water.svg) no-repeat center center / 100%;
}
span.product-branch-icon.thermal {
  background: url(../../images/de_DE/svg/icon-thermal.svg) no-repeat center center / 100%;
}
div#menu-bg-line {
  background-color: #5e7386;
  border-bottom: 2px solid #ff8a16;
  position: absolute;
  height: 164px;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: -1;
}
div#menu-bg-line div#header-bg-container {
  background-color: #ffffff;
  width: 100%;
  height: 114px;
}
div#menu-bg-line hr {
  background-color: #96a6b3;
  border: 0px;
  display: none;
  height: 1px;
  margin-top: 50px;
  width: 100%;
}
div#subMenu {
  border: 1px solid #FFFFFF;
  padding-left: 0px;
  padding-right: 0px;
}
#stepsContainer {
  clear: none;
  margin-top: -22px;
}
span.step {
  background-color: #ff8a16;
  border: 1px solid #FFFFFF;
  display: inline-block;
  height: 11px;
  width: 11px;
}
span.step.active {
  background-color: #96a6b3;
}
textarea {
  resize: none;
}
#main {
  float: left;
  padding-bottom: 41px;
}
#accordionMenu {
  margin-bottom: 41px;
  padding-left: 0px;
  padding-right: 0px;
}
#dashboard {
  border: 1px solid #96A6B3;
  padding-left: 0px;
  padding-right: 0px;
}
#dashboard .highlight {
  color: #FF8A16;
}
.highlight {
  color: #1a3552;
}
.mirrored {
  transform: scaleX(-1);
  -ms-transform: scale(-1);
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  display: inline-block;
}
.mv-type {
  background-color: #E0E4E8;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  text-align: center;
}
.mv-pre-comma {
  background-color: #485f73;
  border-left: 1px solid #FFFFFF;
  color: #FFFFFF;
  font-family: 'PT-Sans-bold';
  text-align: center;
}
.mv-pre-comma.not-available {
  background-color: #E0E4E8;
  color: #E0E4E8;
}
.mv-comma {
  background-color: #E0E4E8;
  border-left: 1px solid #FFFFFF;
  color: #485f73;
  font-family: 'PT-Sans-bold';
  text-align: center;
}
.mv-post-comma {
  background-color: #E0E4E8;
  border-left: 1px solid #FFFFFF;
  color: #485f73;
  font-family: 'PT-Sans-bold';
  text-align: center;
}
.mv-unit {
  background-color: #E0E4E8;
  border-left: 1px solid #FFFFFF;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  text-align: center;
}
footer {
  background-color: #FFFFFF;
  border-top: 1px solid #96a6b3;
  min-height: 40px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}
footer.unfixed {
  clear: both;
  float: left;
  position: static;
  width: 100%;
}
#waitLoader {
  display: none;
}
div.input-seperator {
  margin-left: -15px;
}
div.input-seperator:before {
  content: "—";
  float: left;
  font-family: 'PT-Sans-bold';
  margin-left: -22px;
  margin-top: 4px;
}
.radio-inline.first-column,
.checkbox-inline.first-column {
  margin-top: -11px;
}
.radio-check-table {
  display: table;
  margin-top: -4px;
  width: 100%;
}
.radio-check-table-row {
  display: table-row;
}
.radio-check-table-cell {
  display: table-cell;
  vertical-align: top;
}
.checkbox-inline .radio-check-table-cell .prettycheckbox label,
.radio-inline .radio-check-table-cell .prettyradio label {
  font-weight: normal;
  margin-top: 4px;
}
.checkbox-row .radio-check-table-cell > .checkbox,
.radio-row .radio-check-table-cell > .checkbox,
.checkbox-row .radio-check-table-cell .radio,
.radio-row .radio-check-table-cell .radio {
  margin-left: -15px;
}
.checkbox-row .radio-check-table-cell .prettycheckbox label,
.radio-row .radio-check-table-cell .prettyradio label {
  float: none;
  margin-left: 16px;
  margin-top: 4px;
  width: 100%;
}
.radio-check-table-cell span.popover-tooltip {
  background-color: #ffffff;
  display: inline-block;
  margin-top: 10px;
  margin-left: 9px;
}
span.popover-tooltip {
  background-color: #ffffff;
}
.striped-form-group > .form-group:nth-child(2n+1) {
  background-color: #F9F9F9;
}
.striped-form-group > .form-group:nth-child(2n) {
  background-color: #FFF;
}
h4.read {
  font-family: 'PT-Sans';
  font-weight: normal;
}
h4.read a > span.using-glyphicons {
  color: #cacfd4;
}
#postboxAccordion .prettycheckbox {
  float: left;
  margin-left: -8px;
  margin-top: -5px;
  margin-right: -8px;
}
.checkAll {
  margin-bottom: -4px;
  margin-left: 28px;
  margin-right: -28px;
}
.postbox-pdf-icon {
  vertical-align: top;
}
.postbox-button-delete {
  background-color: transparent;
  border: 0px;
  float: right;
  margin-left: 10px;
  margin-top: -1px;
  padding: 0px;
}
.product-price-container {
  /*background-color: #F3F4F6;*/
}
.product-price-container .headline {
  background-color: #5e7386;
  color: #FFFFFF;
  padding: 5px 15px;
  font-family: 'PT-Sans-bold';
}
.product-price-container > .detail-prices {
  border-left: 1px solid #E0E4E8;
  border-right: 1px solid #E0E4E8;
  border-top: 1px solid #E0E4E8;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  background-color: #F3F4F6;
}
.product-price-container .main-price-line {
  background-color: #5e7386;
  color: #FFFFFF;
  padding: 5px 15px 5px 0px;
  text-align: right;
}
.product-price-container .main-price-line > .big-price {
  font-family: 'PT-Sans-bold';
  font-size: 120%;
}
.product-price-container .bonus-headline {
  background-color: #96a6b3;
  color: #FFFFFF;
  padding: 5px 15px;
  font-family: 'PT-Sans-bold';
}
.product-price-container > .bonus-prices {
  border-left: 1px solid #E0E4E8;
  border-right: 1px solid #E0E4E8;
  border-top: 1px solid #E0E4E8;
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  background-color: #F3F4F6;
}
.product-price-container > .bonus-prices .popover-tooltip {
  background-color: transparent;
}
.product-price-container .bonus-main-price-line {
  background-color: #96a6b3;
  color: #FFFFFF;
  padding: 5px 15px 5px 0px;
  text-align: right;
  margin-bottom: 15px;
}
.product-price-container .bonus-main-price-line > .big-price {
  font-family: 'PT-Sans-bold';
  font-size: 120%;
}
.product-price-details {
  padding-left: 30px;
}
.product-price-details > ul {
  margin-left: -23px;
}
.product-calculator-branch {
  margin-bottom: -4px;
  float: left;
  min-width: 130px;
}
.product-calculator-branch.power .prettyradio label img {
  height: 25px;
  width: 25px;
  margin-left: -5px;
  margin-right: -2px;
  margin-top: -3px;
}
.product-calculator-branch.gas .prettyradio label img {
  height: 25px;
  width: 25px;
  margin-left: -5px;
  margin-right: -1px;
  margin-top: -3px;
}
.product-calculator-branch.water .prettyradio label img {
  height: 25px;
  width: 25px;
  margin-left: 0px;
  margin-right: 3px;
  margin-top: -3px;
}
.product-calculator-branch.thermal .prettyradio label img {
  height: 25px;
  width: 25px;
  margin-left: -3px;
  margin-right: 3px;
  margin-top: -3px;
}
.product-calculator-meter-type {
  margin-bottom: -4px;
  float: left;
  min-width: 105px;
}
.product-calculator-slider-grafix {
  padding: 15px 0px 8px 0px;
}
#process-layout-container.contract-creation {
  max-width: 1140px;
}
div.footnote-container {
  font-size: 13px;
  opacity: 0.5;
}
div.footnote-container p {
  margin-bottom: 0px;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mr--2 {
  margin-right: -2px !important;
}
.mr-5 {
  margin-right: 5px !important;
}
.ml-5 {
  margin-left: 5px !important;
}
.ml-10 {
  margin-left: 10px !important;
}
.ml-25 {
  margin-left: 25px !important;
}
.mt--30 {
  margin-top: -30px !important;
}
.mt--20 {
  margin-top: -20px !important;
}
.mt--10 {
  margin-top: -10px !important;
}
.mt-5 {
  margin-top: 5px !important;
}
.mb--4 {
  margin-bottom: -4px !important;
}
.mb--10 {
  margin-bottom: -10px !important;
}
.mb--15 {
  margin-bottom: -15px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
/* collect overwritten bootstrap less'ns */
table {
  max-width: 100.2%;
}
.table-responsive {
  border: 1px solid #e0e4e8;
  margin-top: 10px;
  overflow-x: auto;
  overflow-y: hidden;
}
.table {
  margin-top: -1px;
  margin-left: -1px;
  margin-bottom: -1px;
  max-width: 100.2%;
  width: 100.1%;
}
.table,
td {
  border: 1px solid #e0e4e8;
  border-right: 0px;
}
.table > thead > tr > th {
  background-color: #f3f4f6;
  border: 1px solid #e0e4e8;
  border-right: 0px;
  border-bottom-color: #9FABB7;
}
.table-striped > tbody > tr:nth-child(2n+1) > td,
.table-striped > tbody > tr:nth-child(2n+1) > th {
  background-color: #FFF;
}
.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #F9F9F9;
}
.table-responsive > table.sum-table > tbody > tr:last-child > td {
  border-top: 1px double #ff8a16;
}
.table-responsive > table.sum-table > tfoot > tr > td {
  border-top: 1px double #ff8a16;
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
}
.footable > thead > tr > th > span.footable-sort-indicator {
  padding-left: 8px;
}
.btn {
  border-radius: 0px;
  font-family: 'PT-Sans-bold';
  font-size: 16px;
  padding: 3px 12px 4px;
}
.btn-default {
  background-color: #FFFFFF;
  border-color: #5e7386;
  color: #485f73;
}
.btn-default:hover,
.btn-default:focus {
  background-color: #95a3b1;
  border-color: #5e7386;
  color: #FFFFFF;
}
.btn-default:hover > .using-glyphicons,
.btn-default:focus > .using-glyphicons {
  color: #FFFFFF;
}
.selectpicker.btn-default {
  color: #555555;
  font-size: 14px;
  padding: 8px 12px 7px;
}
.btn-primary {
  background-color: #5e7386;
  border-color: #1A3552;
  color: #FFFFFF;
}
.btn-primary:hover,
.btn-primary:focus {
  background-color: #ff8a16;
  border-color: #485f73;
  color: #FFFFFF;
}
div.wait-button {
  background-color: #5e7386;
  border-color: #5e7386;
  color: #FFFFFF;
}
div.wait-button:hover {
  background-color: #5e7386;
  border-color: #5e7386;
  color: #FFFFFF;
  cursor: default;
}
.control-label.value-label {
  text-align: left;
}
.form-control {
  background-image: none;
  border-color: #96a6b3;
  border-radius: 0px;
  box-shadow: none;
  height: 37px;
  -webkit-box-shadow: inset 0px -8px 6px -6px #e9ecef;
  -moz-box-shadow: inset 0px -8px 6px -6px #e9ecef;
  box-shadow: inset 0px -8px 6px -6px #e9ecef;
}
.form-control[readonly=readonly],
.form-control[readonly=readonly]:focus {
  border-color: #e0e4e8;
  background-color: #FFFFFF;
  -webkit-box-shadow: inset 0px -8px 6px -6px #f3f5f7;
  -moz-box-shadow: inset 0px -8px 6px -6px #f3f5f7;
  box-shadow: inset 0px -8px 6px -6px #f3f5f7;
}
.form-control[disabled=disabled],
.form-control[disabled=disabled]:focus {
  border-color: #e0e4e8;
  background-color: #f3f4f6;
  -webkit-box-shadow: inset 0px -8px 6px -6px #f3f5f7;
  -moz-box-shadow: inset 0px -8px 6px -6px #f3f5f7;
  box-shadow: inset 0px -8px 6px -6px #f3f5f7;
}
.form-control.alert-danger,
.form-control.alert-danger:focus {
  -webkit-box-shadow: inset 0px -7px 6px -6px #e7cdf2;
  -moz-box-shadow: inset 0px -7px 6px -6px #e7cdf2;
  box-shadow: inset 0px -7px 6px -6px #e7cdf2;
}
.form-control.alert-info,
.form-control.alert-info:focus {
  -webkit-box-shadow: inset 0px -7px 6px -6px #e7dcd2;
  -moz-box-shadow: inset 0px -7px 6px -6px #e7dcd2;
  box-shadow: inset 0px -7px 6px -6px #e7dcd2;
}
.form-control:focus,
.form-control.alert-default:focus {
  border-color: #5e7386;
  -webkit-box-shadow: inset 0px -8px 6px -6px #e9ecef;
  -moz-box-shadow: inset 0px -8px 6px -6px #e9ecef;
  box-shadow: inset 0px -8px 6px -6px #e9ecef;
}
.input-group-addon {
  border-left: 0 none;
}
.bootstrap-select.btn-group .btn .filter-option {
  width: 99%;
}
.input-group.alert-danger .bootstrap-select.form-control button {
  background-color: #ffeadf;
  border-color: #ff0000;
  color: #ff0000;
}
.input-group.alert-danger .bootstrap-select.form-control button > span.caret {
  color: #ff0000;
}
.input-group.alert-info .bootstrap-select.form-control button {
  background-color: #fff8ea;
  border-color: #ea7000;
  color: #ea7000;
}
.input-group.alert-info .bootstrap-select.form-control button > span.caret {
  color: #ea7000;
}
.form-group-panel-default .form-group {
  border-bottom: 1px solid #E0E4E8;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 15px;
}
.form-group-panel-default .form-group .key-label {
  font-family: 'PT-Sans-bold';
  font-weight: normal;
  text-align: left;
  margin-left: -15px;
  padding-top: 0px;
}
.form-group-panel-default .form-group .value-label {
  font-family: 'PT-Sans';
  font-weight: normal;
  text-align: left;
  margin-left: -10px;
  padding-top: 0px;
}
.form-group-panel-default .form-group .btn {
  margin-right: -15px !important;
}
.form-group-panel-default .form-group:last-child {
  border-bottom: 0px;
  margin-bottom: -10px;
}
.form-group-panel-default > h4 {
  font-family: 'PT-Sans-bold';
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.form-group-panel-default > h4:first-child {
  margin-top: 10px;
}
.form-group-panel-radio-check .form-group {
  border-bottom: 1px solid #E0E4E8;
  margin-left: 0px;
  margin-right: 0px;
  padding-bottom: 15px;
}
.form-group-panel-radio-check .form-group .key-label {
  font-family: 'PT-Sans-bold';
  text-align: left;
  margin-left: -15px;
  padding-top: 0px;
  padding-left: 30px;
}
.form-group-panel-radio-check .form-group .value-label {
  font-family: 'PT-Sans';
  font-weight: normal;
  text-align: left;
  margin-left: 10px;
  padding-top: 0px;
}
.form-group-panel-radio-check .form-group:last-child {
  border-bottom: 0px;
  margin-bottom: -10px;
}
textarea.form-control {
  padding-top: 8px;
}
.radio,
.checkbox {
  padding-left: 20px;
}
input[type=password].password-weak {
  background-color: #FFEADF;
}
input[type=password].password-medium {
  background-color: #FFF8EA;
}
input[type=password].password-strong {
  background-color: #D6F5DE;
}
.caret {
  margin-left: 2px;
  margin-top: 11px;
  vertical-align: top;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
}
.open > .dropdown-menu {
  border: 1px solid #96a6b3;
  border-radius: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.open > .dropdown-menu > li a {
  border-bottom: 1px solid #e0e4e8;
  color: #485f73;
  font-size: 16px;
}
.open > .dropdown-menu > li a:hover,
.open > .dropdown-menu > li a:focus {
  background-color: #f3f4f6;
  border-left: 6px solid  #ff8a16;
  padding-left: 14px;
}
.open > .dropdown-menu > li:last-child a {
  border-bottom: 0px;
}
.navbar-right .dropdown-menu {
  /*left: 0px;*/
  right: -10px;
}
.navbar-right .caret {
  margin-left: 7px;
  margin-top: 11px;
}
.typeahead.dropdown-menu {
  border: 1px solid #5e7386;
  border-radius: 0px;
}
.typeahead.dropdown-menu > .active > a,
.typeahead.dropdown-menu .active > a:hover,
.typeahead.dropdown-menu > .active > a:focus {
  background-color: #5e7386;
}
.input-group-addon {
  background-color: #F3F4F6;
  background-image: none;
  border-color: #96a6b3;
  border-radius: 0px;
  color: #485f73;
}
.input-group .form-control {
  z-index: auto;
}
.navbar-default {
  background: transparent;
  border: 0px;
  border-radius: 0px;
  box-shadow: none;
}
#topMenu .navbar-nav > li > a {
  color: #fff;
  font-size: 18px;
  padding-bottom: 13px;
  box-shadow: none;
}
#topMenu .navbar-nav > li > a .caret {
  margin-top: 9px;
}
#topMenu .navbar-nav > li > a:hover {
  background-color: #95a3b1;
}
#topMenu .navbar-nav > li > a.no-toggle:hover {
  background-color: transparent;
  cursor: default;
}
#topMenu .navbar-default .navbar-nav > .active > a {
  background-image: none;
  background-color: #95a3b1;
}
#topMenu .navbar-default .navbar-nav > .open > a {
  background-image: none;
  background-color: #95a3b1;
  color: #ffffff;
}
#topMenu .navbar-collapse {
  padding-right: 0px;
}
#topMenu .navbar-right li.dropdown a.dropdown-toggle {
  margin-right: -10px;
  padding-right: 10px;
}
#topMenu .navbar-right li.dropdown a.dropdown-toggle > span {
  display: inline-block;
  line-height: 22px;
  margin-bottom: -5px;
  max-width: 211px;
  overflow-x: hidden;
  white-space: nowrap;
  overflow-y: hidden;
}
.navbar-default .navbar-brand {
  color: #fff;
  height: 48px;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #fff;
  background-color: #95a3b1;
}
.navbar-default .navbar-toggle {
  border-color: #FFFFFF;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #96A6B3;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #FFFFFF;
}
footer .navbar-nav {
  float: none;
  margin: 0px auto;
}
footer .navbar-nav > li > a {
  color: #1A3552;
  padding-top: 9px;
  padding-bottom: 10px;
}
footer .navbar-nav > li > a:hover,
footer .navbar-nav > li > a:focus {
  background-color: #FFFFFF;
  color: #1A3552;
}
.breadcrumb {
  background-color: transparent;
  border-radius: 0px;
  margin-bottom: 7px;
  padding: 11px 0px 4px 15px;
}
.breadcrumb li.pull-right:before {
  content: "";
}
.breadcrumb a {
  color: #FFFFFF;
}
.breadcrumb > li + li:before {
  color: #FFFFFF;
  font-family: 'PT-Sans' !important;
}
.breadcrumb > .active {
  color: #FFFFFF;
  font-family: 'PT-Sans-bold';
}
.pagination > li > a,
.pagination > li > span {
  border-color: #96a6b3;
  color: #485f73;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #F3F4F6;
  border-color: #96a6b3;
  color: #1a3552;
  font-family: 'PT-Sans-bold';
}
.control-label.text-left {
  font-family: 'PT-Sans-bold';
  font-weight: normal;
}
.control-label.alert-danger,
.control-label.alert-info {
  background-color: transparent;
}
label a {
  color: #ea7000;
}
label a:hover,
label a:focus {
  color: #ea7000;
  text-decoration: underline;
}
.form-group > .col-xs-24.text-center label {
  float: left;
}
.alert {
  background-image: none;
  border-radius: 0px;
  box-shadow: none;
}
.alert-danger,
.alert-danger:focus {
  background-color: #ffeadf;
  border-color: #ff0000;
  color: #ff0000;
}
.alert-danger .using-glyphicons {
  color: #ff0000;
  opacity: 1;
}
.alert-info,
.alert-info:focus {
  background-color: #fff8ea;
  border-color: #ea7000;
  color: #ea7000;
}
.alert-info .using-glyphicons {
  color: #ea7000;
  opacity: 1;
}
button.close {
  margin-right: -10px;
  margin-top: -10px;
}
.close.using-glyphicons {
  font-size: 15px;
  font-weight: normal;
}
.alert ul {
  list-style-type: none;
  padding-top: 5px;
}
.alert ul > li {
  line-height: 25px;
  margin-left: -25px;
}
p.alert-danger,
p.alert-info {
  padding: 5px 10px;
}
#subMenu .list-group-item,
#accordionMenu .list-group-item {
  border-top: 1px solid #e0e4e8;
  border-left: 0px;
  border-right: 0px;
  padding-left: 40px;
  color: #485f73;
}
#subMenu .list-group-item:first-child,
#accordionMenu .list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 0px !important;
}
#subMenu .list-group-item:last-child,
#accordionMenu .list-group-item:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: 0px !important;
}
.list-group-item-info {
  background-color: rgba(255, 138, 22, 0.7);
  color: #ffffff !important;
  font-family: 'PT-Sans-bold';
  padding-left: 26px !important;
}
a.list-group-item:hover,
a.list-group-item:focus,
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #f3f4f6;
  border-bottom: 1px solid #e0e4e8;
  border-left: 6px solid  #ff8a16 !important;
  padding-left: 34px !important;
  font-family: 'PT-Sans-bold';
}
#dashboard {
  /*background-color: transparent;*/
  background-color: #FFFFFF;
}
#dashboard .panel-body {
  /*background-image: url(../../images/de_DE/trans-pix.png);*/
}
#dashboard-panel-body .panel-body {
  background-image: none;
}
.panel,
.panel-group .panel {
  border-radius: 0px;
  box-shadow: none;
}
.panel-default {
  border-color: #96a6b3;
  /*#e0e4e8;*/
}
.panel-default .panel-default {
  border-color: #e0e4e8;
}
.panel-heading {
  padding: 9px 15px 10px 15px;
}
.panel-body h3 {
  border-bottom: 1px solid #96a6b3;
  font-family: 'PT-Sans-bold';
  font-size: 18px;
  padding-bottom: 7px;
}
.panel-body h3 .popover-tooltip {
  font-size: 16px;
}
.panel-default > .panel-heading {
  background-image: none;
  background-color: #f3f4f6;
  border-color: #96a6b3;
  color: #1a3552;
  font-family: 'PT-Sans-bold';
}
.panel-default > .panel-heading h3 {
  border-bottom: 0px;
  padding-bottom: 0px;
  padding-right: 25%;
}
a[data-toggle="collapse"] {
  text-decoration: none;
}
a[data-toggle="collapse"] > .caret {
  border-top: 0px;
  border-bottom: 6px solid;
  margin-top: 8px;
}
a[data-toggle="collapse"].collapsed .caret {
  border-bottom: 0px;
  border-top: 6px solid;
}
#accordionMenu .panel {
  border: 1px solid #FFFFFF;
  background-color: transparent;
}
#accordionMenu .panel-heading {
  background-color: rgba(255, 138, 22, 0.7);
  border-bottom: 0px;
  border-radius: 0px;
  padding: 0px;
}
#accordionMenu .panel-heading a {
  color: #FFFFFF;
  display: block;
  padding: 10px 15px 10px 26px;
}
.panel-heading.radio-checkbox-heading {
  padding: 3px 15px 0px 15px;
}
.panel-body .radio-checkbox-headline-table {
  display: table;
  margin-left: 20px;
}
.panel-body .radio-checkbox-headline-table-row {
  display: table-row;
}
.panel-body .radio-checkbox-headline-table-cell {
  display: table-cell;
}
.panel-body .radio-checkbox-headline-table-cell.has-pretty-child label {
  font-family: 'PT-Sans';
  font-size: 16px;
  margin: 3px 0px 0px 5px;
}
.panel-title {
  font-size: 18px;
  position: relative;
}
.modal-content {
  border-radius: 0px;
  border: 1px solid #96a6b3;
}
.modal-header {
  background-color: #f3f4f6;
  border-bottom: 1px solid #96a6b3;
  padding-top: 7px;
  padding-bottom: 8px;
  color: #1a3552;
  font-family: 'PT-Sans-bold';
}
.modal-header > h4 {
  font-size: 17px;
}
.modal-header .close {
  font-size: 15px;
  font-weight: normal;
  font-family: 'Glyphicons Halflings';
  color: #485f73;
  opacity: 1;
  margin-top: 5px;
}
.modal-header .close:hover {
  color: #485f73;
}
.modal-footer {
  border-top: 1px solid #eeeeee;
}
.popover {
  border-radius: 0px;
  background-color: #F3F4F6;
  border-color: #96a6b3;
}
.popover.alert-danger {
  background-color: #ffeadf!important;
  border-color: #FF0000!important;
}
.popover.alert-info {
  background-color: #fff8ea!important;
  border-color: #ea7000!important;
}
.popover.alert-password-weak {
  background-color: #FFEADF !important;
}
.popover.alert-password-medium {
  background-color: #FFF8EA !important;
}
.popover.alert-password-strong {
  background-color: #D6F5DE !important;
}
.popover.top > .arrow {
  border-top-color: #485f73;
}
.popover.top > .arrow:after {
  border-top-color: #F3F4F6;
}
.popover.top.alert-danger > .arrow {
  border-top-color: #FF0000;
}
.popover.top.alert-danger > .arrow:after {
  border-top-color: #FCE6E6;
}
.popover.top.alert-info > .arrow {
  border-top-color: #EA7000;
}
.popover.top.alert-info > .arrow:after {
  border-top-color: #FFF8EA;
}
.popover.top.alert-password-weak > .arrow:after {
  border-top-color: #FFEADF;
}
.popover.top.alert-password-medium > .arrow:after {
  border-top-color: #FFF8EA;
}
.popover.top.alert-password-strong > .arrow:after {
  border-top-color: #D6F5DE;
}
.popover-content {
  font-size: 14px;
}
/* the left variante */
.popover.left > .arrow {
  border-left-color: #485f73;
}
.popover.left > .arrow:after {
  border-left-color: #F3F4F6;
}
.popover.left.alert-danger > .arrow {
  border-left-color: #FF0000;
}
.popover.left.alert-danger > .arrow:after {
  border-left-color: #FCE6E6;
}
.popover.left.alert-info > .arrow {
  border-left-color: #EA7000;
}
.popover.left.alert-info > .arrow:after {
  border-left-color: #FFF8EA;
}
.popover.left.alert-password-weak > .arrow:after {
  border-left-color: #FFEADF;
}
.popover.left.alert-password-medium > .arrow:after {
  border-left-color: #FFF8EA;
}
.popover.left.alert-password-strong > .arrow:after {
  border-left-color: #D6F5DE;
}
/* topRight */
.popover.topRight > .arrow {
  border-top-color: #485f73;
}
.popover.topRight > .arrow:after {
  border-top-color: #F3F4F6;
}
.popover.topRight.alert-danger > .arrow {
  border-top-color: #FF0000;
}
.popover.topRight.alert-danger > .arrow:after {
  border-top-color: #FCE6E6;
}
.popover.topRight.alert-info > .arrow {
  border-top-color: #EA7000;
}
.popover.topRight.alert-info > .arrow:after {
  border-top-color: #FFF8EA;
}
.popover.topRight.alert-password-weak > .arrow:after {
  border-top-color: #FFEADF;
}
.popover.topRight.alert-password-medium > .arrow:after {
  border-top-color: #FFF8EA;
}
.popover.topRight.alert-password-strong > .arrow:after {
  border-top-color: #D6F5DE;
}
/* topLeft */
.popover.topLeft > .arrow {
  border-top-color: #485f73;
}
.popover.topLeft > .arrow:after {
  border-top-color: #F3F4F6;
}
.popover.topLeft.alert-danger > .arrow {
  border-top-color: #FF0000;
}
.popover.topLeft.alert-danger > .arrow:after {
  border-top-color: #FCE6E6;
}
.popover.topLeft.alert-info > .arrow {
  border-top-color: #EA7000;
}
.popover.topLeft.alert-info > .arrow:after {
  border-top-color: #FFF8EA;
}
.popover.topLeft.alert-password-weak > .arrow:after {
  border-top-color: #FFEADF;
}
.popover.topLeft.alert-password-medium > .arrow:after {
  border-top-color: #FFF8EA;
}
.popover.topLeft.alert-password-strong > .arrow:after {
  border-top-color: #D6F5DE;
}
/* bottomRight */
.popover.bottomRight > .arrow {
  border-bottom-color: #485f73;
}
.popover.bottomRight > .arrow:after {
  border-bottom-color: #F3F4F6;
}
.popover.bottomRight.alert-danger > .arrow {
  border-bottom-color: #FF0000;
}
.popover.bottomRight.alert-danger > .arrow:after {
  border-bottom-color: #FCE6E6;
}
.popover.bottomRight.alert-info > .arrow {
  border-bottom-color: #EA7000;
}
.popover.bottomRight.alert-info > .arrow:after {
  border-bottom-color: #FFF8EA;
}
.popover.bottomRight.alert-password-weak > .arrow:after {
  border-bottom-color: #FFEADF;
}
.popover.bottomRight.alert-password-medium > .arrow:after {
  border-bottom-color: #FFF8EA;
}
.popover.bottomRight.alert-password-strong > .arrow:after {
  border-bottom-color: #D6F5DE;
}
/* collect overwritten bootstrap plugin less'ns */
.datepicker {
  border-radius: 0px;
  margin-top: 6px;
  margin-left: 0px;
  margin-bottom: 45px;
  z-index: 998;
}
.datepicker table td {
  border: 0px;
}
.datepicker th.datepicker-switch {
  width: 145px;
}
.datepicker th.prev,
.datepicker th.datepicker-switch,
.datepicker th.next {
  background-color: #5E7386;
  border-radius: 0px;
  color: #fff;
}
.datepicker th.prev:hover,
.datepicker th.datepicker-switch:hover,
.datepicker th.next:hover {
  background-color: #E0E4E8;
  border-radius: 0px;
  color: #485f73;
}
.datepicker td,
.datepicker td span.month,
.datepicker td span.year {
  border-radius: 0px;
  color: #485f73;
}
.datepicker td.day:hover,
.datepicker td span.month:hover,
.datepicker td span.year:hover {
  background-color: #E0E4E8;
}
.datepicker td.day.old {
  color: #95A3B1;
}
.datepicker td.today,
.datepicker td.today:hover,
.datepicker td.active:hover,
.datepicker td.active:hover:hover,
.datepicker td.active:focus,
.datepicker td.active:hover:focus,
.datepicker td.active:active,
.datepicker td.active:hover:active,
.datepicker td.active.active,
.datepicker td.active:hover.active,
.datepicker td.active.disabled,
.datepicker td.active:hover.disabled,
.datepicker td.active[disabled],
.datepicker td.active:hover[disabled],
.datepicker td span.active:hover,
.datepicker td span.active:focus,
.datepicker td span.active:active,
.datepicker td span.active.active,
.datepicker td span.active.disabled,
.datepicker td span.active[disabled] {
  color: #ffffff;
  background-color: #5E7386;
  background-image: none;
  font-family: 'PT-Sans-bold';
  text-shadow: none;
}
/* **** ohne Pfeil ******* */
.datepicker {
  margin: 0px;
}
.datepicker:after,
.datepicker:before {
  border-bottom: 0px;
}
.bootstrap-select.form-control button {
  background-image: none;
  border-color: #96a6b3;
  border-radius: 0px;
  box-shadow: none;
  -webkit-box-shadow: inset 0px -7px 6px -6px #e9ecef;
  -moz-box-shadow: inset 0px -7px 6px -6px #e9ecef;
  box-shadow: inset 0px -7px 6px -6px #e9ecef;
}
.btn-group.open .dropdown-toggle {
  box-shadow: none;
}
.selectpicker.btn-default,
.bootstrap-select.open .dropdown-toggle.btn-default {
  background-color: #fff;
  border-color: #5e7386;
  -webkit-box-shadow: inset 0px -7px 6px -6px #e9ecef;
  -moz-box-shadow: inset 0px -7px 6px -6px #e9ecef;
  box-shadow: inset 0px -7px 6px -6px #e9ecef;
}
.bootstrap-select > .dropdown-menu.open {
  margin-top: 0px;
}
.inner.selectpicker > li > a {
  font-size: 14px;
  padding-left: 14px;
}
.inner.selectpicker > li > a:hover,
.inner.selectpicker > li > a:focus {
  border-left: 0px;
  font-family: 'PT-Sans';
}
.bootstrap-select.btn-group > .disabled {
  border-color: #e0e4e8;
  background-color: #f3f4f6;
  -webkit-box-shadow: inset 0px -7px 6px -6px #f3f5f7;
  -moz-box-shadow: inset 0px -7px 6px -6px #f3f5f7;
  box-shadow: inset 0px -7px 6px -6px #f3f5f7;
  opacity: 1;
}
.bootstrap-select.form-control button:focus {
  border-color: #5e7386;
  -webkit-box-shadow: inset 0px -7px 6px -6px #e9ecef;
  -moz-box-shadow: inset 0px -7px 6px -6px #e9ecef;
  box-shadow: inset 0px -7px 6px -6px #e9ecef;
}
.bootstrap-select.form-control button span.caret {
  color: #485f73;
}
.bootstrap-select button {
  font-family: 'PT-Sans';
}
.slider-horizontal {
  float: left;
  margin-top: 11px;
  width: 100% !important;
}
.slider-horizontal .tooltip {
  display: none;
}
.slider-handle {
  background-color: #e0e4e8;
  background-image: none;
  border: 1px solid #96A6B3;
  box-shadow: none;
  opacity: 1.0;
}
.slider-value-container {
  display: inline-block;
  margin-top: 8px;
}
.tooltip-arrow {
  border-top-color: #e0e4e8 !important;
}
.tooltip-inner {
  background-color: #e0e4e8;
  color: #485f73;
}
.button_strength {
  display: none;
}
.strength_meter {
  background: none transparent;
  position: absolute;
  top: 37px;
  left: 0px;
  width: 100%;
  height: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.strength_meter div {
  color: transparent;
  height: 10px;
}
.veryweak {
  background-color: #E8110F;
  border-color: #e8110f !important;
}
.weak {
  background-color: #FF6600;
  border-color: #FF6600 !important;
}
.medium {
  background-color: #FFCC00;
  border-color: #FFCC00 !important;
}
.strong {
  background-color: #00CC00;
  border-color: #00CC00 !important;
}
/* breakpoint styles */
/* ***** 
itc-min: 320

xs: 480 - 767 (bootstrap standard)
sm: 768 - 991 (bootstrap standard)
md: 992 - 1199 (bootstrap standard)
lg: 1200 - ∞ (bootstrap standard)

itc-max: 1280
enms-max: 1554
 ***** */
@media (min-width: 991px) {
  body.side-collapse .navbar-right li.dropdown a.dropdown-toggle > span {
    max-width: none !important;
  }
}
@media (max-width: 1199px) {
  body.classic #menu-bg-line {
    height: 214px !important;
  }
  body.classic #menu-bg-line hr {
    display: block !important;
  }
  body.classic ul.nav.navbar-nav.visible-md {
    clear: both;
    margin-left: -30px;
  }
  body.classic .navbar-brand {
    height: 50px;
  }
  body.classic .navbar-right li.dropdown a.dropdown-toggle {
    border-bottom: 2px solid #5E7386;
  }
  body.classic .navbar-right li.dropdown a.dropdown-toggle:hover,
  body.classic .navbar-right li.dropdown a.dropdown-toggle:focus {
    border-bottom: 2px solid #95A3B1;
  }
  body.classic .navbar-right li.dropdown a.dropdown-toggle > span {
    max-width: none !important;
  }
  body.top-drop #menu-bg-line {
    height: 214px !important;
  }
  body.top-drop #menu-bg-line hr {
    display: block !important;
  }
  body.top-drop #top-drop-menu {
    clear: both;
    margin-left: -30px;
  }
  body.top-drop .navbar-brand {
    height: 50px;
  }
  body.top-drop .navbar-right li.dropdown a.dropdown-toggle {
    border-bottom: 2px solid #5E7386;
  }
  body.top-drop .navbar-right li.dropdown a.dropdown-toggle:hover,
  body.top-drop .navbar-right li.dropdown a.dropdown-toggle:focus {
    border-bottom: 2px solid #95A3B1;
  }
  body.top-drop .navbar-right li.dropdown a.dropdown-toggle > span {
    max-width: none !important;
  }
}
@media (max-width: 991px) {
  #topMenu ul.nav.visible-sm {
    clear: both;
    margin-left: -15px;
  }
  .navbar-brand {
    margin-left: 0px !important;
    height: 50px !important;
  }
  .navbar-right li.dropdown a.dropdown-toggle {
    padding-bottom: 15px !important;
  }
  .navbar-right li.dropdown a.dropdown-toggle > span {
    max-width: none !important;
  }
  body.classic #menu-bg-line {
    height: 214px !important;
  }
  body.classic #menu-bg-line hr {
    display: block !important;
  }
  body.classic .navbar-right li.dropdown a.dropdown-toggle {
    border-bottom: 1px solid #96a6b3 !important;
  }
  body.classic #topMenu .navbar-nav > li > a {
    padding-bottom: 12px;
  }
  body.top-drop #menu-bg-line {
    height: 214px !important;
  }
  body.top-drop #menu-bg-line hr {
    display: block !important;
  }
  body.top-drop .navbar-right li.dropdown a.dropdown-toggle {
    border-bottom: 0px !important;
  }
  body.top-drop .navbar-brand {
    margin-left: -15px !important;
  }
  body.side-collapse #menu-bg-line {
    height: 214px !important;
  }
  body.side-collapse #menu-bg-line hr {
    display: block !important;
  }
}
@media (max-width: 767px) {
  body.classic #menu-bg-line,
  body.top-drop #menu-bg-line,
  body.side-collapse #menu-bg-line {
    height: 164px !important;
  }
  body.classic #menu-bg-line hr,
  body.top-drop #menu-bg-line hr,
  body.side-collapse #menu-bg-line hr {
    display: none !important;
  }
  body.classic .navbar-brand,
  body.top-drop .navbar-brand,
  body.side-collapse .navbar-brand {
    height: 48px !important;
  }
  header {
    height: 55px;
  }
  img#logo {
    height: 33px;
    margin-top: 12px;
  }
  div#branches {
    background: url(../../images/de_DE/svg/branches.svg) no-repeat right top / 84%;
    margin-top: 12px;
    margin-right: 0px;
    height: 33px;
    width: 180px;
  }
  div#menu-bg-line {
    top: -59px;
  }
  #top-drop-menu {
    clear: both;
    margin-left: -30px;
  }
  .navbar-toggle {
    margin-right: 0px;
  }
  .navbar-collapse {
    border: 0px;
    max-height: none;
    padding-right: 15px !important;
  }
  body.top-drop #top-drop-menu {
    margin-left: -15px;
  }
  body.top-drop .navbar-brand {
    margin-left: 0px !important;
  }
  body.top-drop .navbar-nav .open .dropdown-menu > li > a {
    background-color: #FFFFFF;
    color: #485f73;
  }
  body.top-drop .navbar-nav .open .dropdown-menu > li > a:hover,
  body.top-drop .navbar-nav .open .dropdown-menu > li > a:focus {
    background-color: #F3F4F6;
    color: #485f73;
    padding-left: 19px;
  }
  #topMenu .navbar-right {
    padding-right: 10px;
  }
  #topMenu .navbar-right > li.dropdown > a.dropdown-toggle {
    border-bottom: 1px solid  #ff8a16 !important;
  }
  #topMenu .navbar-right > li.dropdown > a.dropdown-toggle:hover,
  #topMenu .navbar-right > li.dropdown > a.dropdown-toggle:focus {
    background-color: #95A3B1;
    color: #FFFFFF;
  }
  .navbar-nav.navbar-right .open .dropdown-menu {
    border-left: 1px solid #96A6B3;
    border-bottom: 1px solid #96A6B3;
    border-right: 1px solid #96A6B3;
    margin-right: -10px;
  }
  .navbar-nav.navbar-right .open .dropdown-menu > li a {
    color: #485f73;
    background-color: #ffffff;
  }
  .navbar-nav.navbar-right .open .dropdown-menu > li a:hover,
  .navbar-nav.navbar-right .open .dropdown-menu > li a:focus {
    background-color: #f3f4f6;
    border-left: 6px solid  #ff8a16;
    color: #485f73;
    padding-left: 19px;
  }
  #topMenu .navbar-nav > li > a.dropdown-toggle {
    border-bottom: 1px solid #96A6B3 !important;
    color: #485f73;
  }
  #topMenu .navbar-nav > li > a.dropdown-toggle:hover,
  #topMenu .navbar-nav > li > a.dropdown-toggle:active {
    color: #FFFFFF;
  }
  #topMenu .navbar-nav > li > a.dropdown-toggle.no-toggle:hover {
    background-color: #FFFFFF;
    color: #485f73;
  }
  #topMenu .navbar-nav > li > a.no-toggle:hover {
    background-color: #5E7386;
  }
  .navbar-nav.visible-xs .open .dropdown-menu {
    border-left: 1px solid #96A6B3;
    border-bottom: 1px solid #96A6B3;
    border-right: 1px solid #96A6B3;
  }
  .navbar-nav.visible-xs .open .dropdown-menu > li a {
    color: #485f73;
    background-color: #FFFFFF;
  }
  .navbar-nav.visible-xs .open .dropdown-menu > li a:hover,
  .navbar-nav.visible-xs .open .dropdown-menu > li a:focus {
    background-color: #f3f4f6;
    border-left: 6px solid  #ff8a16;
    color: #485f73;
    padding-left: 19px;
  }
  #topMenu .navbar-nav > li > a {
    background-color: #f3f4f6;
  }
  .breadcrumb > li + li:before {
    content: "";
    margin-left: -10px;
  }
  #main {
    padding-left: 0px;
    padding-right: 0px;
  }
  .xs-dissolve-min-height {
    min-height: 1px !important;
  }
  footer {
    min-height: 29px;
    padding-bottom: 3px;
  }
  footer .nav > li {
    float: left;
  }
  footer .nav > li a {
    font-size: 13px;
    padding: 3px 0px 0px 15px;
    line-height: 17px;
  }
  .form-group-panel-default .form-group > label.value-label {
    margin-left: -15px;
  }
  .form-group-panel-radio-check .form-group > label.value-label {
    margin-left: 15px;
  }
  .form-group-panel-default .form-group .value-label {
    margin-left: -15px;
  }
  .product-price-details {
    margin-top: 5px;
    padding-left: 5px;
  }
  .caret {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid;
  }
  .checkAll {
    margin-left: 15px;
  }
}
@media (max-width: 800px) {
  body.classic #cage,
  body.top-drop #cage,
  body.side-collapse #cage,
  body.classic footer ul,
  body.top-drop footer ul,
  body.side-collapse footer ul {
    width: 95% !important;
    min-width: 320px;
  }
}
@media (max-width: 499px) {
  footer a {
    font-size: 14px;
  }
}
@media (max-width: 430px) {
  .checkbox-row .radio-check-table-cell.col-xs-2,
  .radio-row .radio-check-table-cell.col-xs-2 {
    padding-left: 0px;
  }
  .open > .dropdown-menu > li a {
    white-space: normal;
  }
}
@media (max-width: 335px) {
  .navbar-toggle,
  #stepsContainer {
    margin-right: 15px;
  }
  #branches {
    display: none;
  }
}
@media (max-width: 386px) {
  .popover .arrow {
    top: 100% !important;
  }
}
@media (max-width: 670px) {
  ul.dropdown-menu[aria-labelledby=customerNoInvoiceNoSelection] {
    width: 70%;
    margin-left: 15%;
  }
}
@media (max-width: 480px) {
  ul.dropdown-menu[aria-labelledby=customerNoInvoiceNoSelection] {
    width: 100%;
    margin-left: 0%;
  }
}
