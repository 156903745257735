
.widget-container {
	.widget-close,
	.miniature,
	.miniature-label {
		display: none;	
	}
}


/* hide the radios */
input[type=radio]:not(old) {
	margin: 0;
	padding: 0;
	font-size: 1em;
	opacity: 0;
	display: none;
}

/* positioning the label of radios */
input[type=radio]:not(old) + label {
	display: inline-block;
	font-family: Verdana, Arial, sans-serif;
	font-weight: normal;
	max-width: 100%;
	float: left;
	
	div.radio-label {
		padding-left: 17px;
		margin-top: -18px;
		margin-right: 15px;
		margin-left: 15px;
		
		img.radio-branch-icon {
			height: 28px;
			width: 28px;
			margin-top: -6px;
			margin-left: -5px;
		}
		
		&:hover {
			cursor: pointer;
		}
	}
}

/* styling unchecked radios [gradient is possible] */
input[type=radio]:not(old) + label > span {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 5px 0px 0px 0px;
	border: 1px solid #7f8080;
	border-radius: 15px;
	background: none #FFFFFF;
	background-image: -moz-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -ms-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -o-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -webkit-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: linear-gradient(#FFFFFF,#FFFFFF);
	vertical-align: bottom;
			
	&:hover {
		cursor: pointer;
	}
}

/* styling checked radios [gradient is possible] */
input[type=radio]:not(old):checked + label > span {
	background-image: -moz-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -ms-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -o-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -webkit-linear-gradient(#FFFFFF,#FFFFFF);
	background-image:  linear-gradient(#FFFFFF,#FFFFFF);
	
	&:hover {
		cursor: pointer;
	}
}

/* bullet inside selected radios [gradient is possible] */
input[type=radio]:not(old):checked + label > span > span {
	display: block;
	width: 8px;
	height: 8px;
	margin: 5px;
	border: 1px solid #0164A8;
	border-radius: 5px;
	background: none #0164A8;
	background-image: -moz-linear-gradient(#0164A8,#0164A8);
	background-image: -ms-linear-gradient(#0164A8,#0164A8);
	background-image: -o-linear-gradient(#0164A8,#0164A8);
	background-image: -webkit-linear-gradient(#0164A8,#0164A8);
	background-image:  linear-gradient(#0164A8,#0164A8);
}

/* styling unchecked disabled radios [gradient is possible] */
input[type=radio]:not(old):disabled + label > span {
	border: 1px solid #d0d0d0;
	background: none #BBBBBB;
	background-image: -moz-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -ms-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -o-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -webkit-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: linear-gradient(#BBBBBB,#BBBBBB);
	&:hover {
		cursor: not-allowed;
	}
}

/* styling checked disabled radios [gradient is possible] */
input[type=radio]:not(old):checked:disabled + label > span {
	background-image: -moz-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -ms-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -o-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -webkit-linear-gradient(#BBBBBB,#BBBBBB);
	background-image:  linear-gradient(#BBBBBB,#BBBBBB);
	&:hover {
		cursor: not-allowed;
	}
}

/* bullet inside selected disabled radios [gradient is possible] */
input[type=radio]:not(old):checked:disabled + label > span > span {
	border: 1px solid #9fabb7;
	background: none #9fabb7;
	background-image: -moz-linear-gradient(#9fabb7,#9fabb7);
	background-image: -ms-linear-gradient(#9fabb7,#9fabb7);
	background-image: -o-linear-gradient(#9fabb7,#9fabb7);
	background-image: -webkit-linear-gradient(#9fabb7,#9fabb7);
	background-image:  linear-gradient(#9fabb7,#9fabb7);
}

/* alert-danger marked radios */
input[type=radio]:not(old) + label.alert-danger {
	background-color: transparent;
}
input[type=radio]:not(old) + label.alert-danger > span {
	border: 1px solid #ff0000;
	background: none #ffeadf;
	background-image: -moz-linear-gradient(#ffeadf,#ffeadf);
	background-image: -ms-linear-gradient(#ffeadf,#ffeadf);
	background-image: -o-linear-gradient(#ffeadf,#ffeadf);
	background-image: -webkit-linear-gradient(#ffeadf,#ffeadf);
	background-image: linear-gradient(#ffeadf,#ffeadf);
}
input[type=radio]:not(old):checked + label.alert-danger > span > span {
	border: 1px solid #ff0000;
	background: none #ff0000;
	background-image: -moz-linear-gradient(#ff0000,#ff0000);
	background-image: -ms-linear-gradient(#ff0000,#ff0000);
	background-image: -o-linear-gradient(#ff0000,#ff0000);
	background-image: -webkit-linear-gradient(#ff0000,#ff0000);
	background-image:  linear-gradient(#ff0000,#ff0000);
}

.radio-row {
	margin-top: 3px;
}



/* hide the checkboxes */
input[type=checkbox]:not(old) {
	margin: 0;
	padding: 0;
	font-size: 1em;
	opacity: 0;
	display: none;
}

/* positioning the label of checkboxes */
input[type=checkbox]:not(old) + label {
	display: inline-block;
	font-family: Verdana, Arial, sans-serif;
	font-weight: normal;
	max-width: 100%;
	float: left;
	
	div.checkbox-label {
		padding-left: 17px;
		margin-top: -18px;
		margin-right: 15px;
		margin-left: 15px;
		
		img.checkbox-branch-icon {
			height: 28px;
			width: 28px;
			margin-top: -6px;
			margin-left: -5px;
		}
		
		&:hover {
			cursor: pointer;
		}
	}
}	

/* styling unchecked checkboxes [gradient is possible] */
input[type=checkbox]:not(old) + label > span {
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 5px 0px 0px 0px;
	border: 1px solid #7f8080;
	border-radius: 4px;
	background: none #FFFFFF;
	background-image: -moz-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -ms-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -o-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -webkit-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: linear-gradient(#FFFFFF,#FFFFFF);
	vertical-align: bottom;
	
	&:hover {
		cursor: pointer;
	}
}

/* styling checked checkboxes [gradient is possible] */
input[type=checkbox]:not(old):checked + label > span {
	background-image: -moz-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -ms-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -o-linear-gradient(#FFFFFF,#FFFFFF);
	background-image: -webkit-linear-gradient(#FFFFFF,#FFFFFF);
	background-image:  linear-gradient(#FFFFFF,#FFFFFF);
	
	&:hover {
		cursor: pointer;
	}
}

/* tick inside selected checkboxes */
input[type=checkbox]:not(old):checked + label > span:before {
	content: '\e013';
	display: block;
	color: #0164A8;
	text-align: center;
	font-family: 'Glyphicons Halflings';
	font-size: 15px;
	line-height: 18px;
	margin-left: -1px;
}

/* styling unchecked disabled checkboxes [gradient is possible] */
input[type=checkbox]:not(old):disabled + label > span {
	border: 1px solid #d0d0d0;		
	background: none #BBBBBB;
	background-image: -moz-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -ms-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -o-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -webkit-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: linear-gradient(#BBBBBB,#BBBBBB);
	&:hover {
		cursor: not-allowed;
	}
}

/* styling checked disabled checkboxes [gradient is possible] */
input[type=checkbox]:not(old):checked:disabled + label > span {
	background-image: -moz-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -ms-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -o-linear-gradient(#BBBBBB,#BBBBBB);
	background-image: -webkit-linear-gradient(#BBBBBB,#BBBBBB);
	background-image:  linear-gradient(#BBBBBB,#BBBBBB);
	&:hover {
		cursor: not-allowed;
	}
}

/* tick inside selected disabled checkboxes */
input[type=checkbox]:not(old):checked:disabled + label > span:before {
	color: #9fabb7;		
}

.checkbox-row {
	margin-top: 3px;
}

#postboxAccordion {
	.panel-heading label {
		float: left;
		margin-right: 5px;
		margin-top: -2px;
		margin-left: -3px;
		span {
			margin-top: 0px;
		}	
	}
}

/* alert-danger marked checkboxes */
input[type=checkbox]:not(old) + label.alert-danger {
	background-color: transparent;
}
input[type=checkbox]:not(old) + label.alert-danger > span {
	border: 1px solid #ff0000;
	background: none #ffeadf;
	background-image: -moz-linear-gradient(#ffeadf,#ffeadf);
	background-image: -ms-linear-gradient(#ffeadf,#ffeadf);
	background-image: -o-linear-gradient(#ffeadf,#ffeadf);
	background-image: -webkit-linear-gradient(#ffeadf,#ffeadf);
	background-image: linear-gradient(#ffeadf,#ffeadf);
}
input[type=checkbox]:not(old):checked + label.alert-danger > span:before {
	color: #ff0000;
}


.widget-container .widget-radio-check-form-group {
	margin-top: -7px;
}
.widget-container.product-calculator div.widget-consumption-input-row {
	margin-top: 4px;
}

.form-group-panel-default .form-group.pb-0 .control-label.key-label {
	label {
		margin-top: -6px;
		margin-bottom: 12px;
	}	
}			