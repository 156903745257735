
.fileinput-remove-button, .kv-fileinput-upload {
  padding: 6px 10px;
}

.btn-file {
  padding: 6px 10px;
}

.fileinput-remove-button {
  display: none;
}

