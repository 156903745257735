
header > .alert {
	position: absolute;
	top: 15px;
	left: 0px;
	right: 0px;
	width: 80%;
	max-width: 939px;
	z-index: 11113;	
	margin-left: auto;
	margin-right: auto;
}

.widget-container .alert {
	position: absolute;
	top: 0px;
	left: 0px;
	margin-left: -1px;
	margin-right: -1px;
	margin-top: -1px;
	width: 293px;
	z-index: 99;
	
	h4 {
		margin-top: -6px;
	}
}