.prettycheckbox label, .prettyradio label {
    margin-top: 4px;
    font-weight: normal;
}

.form-group-panel-default .form-group .key-label img.label-icon {
	margin-left: 65px;
}

.details-label-branch-icon {
	height: 30px;
}

.radio-inline.with-media-icon {
  margin-right: 12px;
}
	