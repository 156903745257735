.btn {   
  font-family: Verdana, Arial, sans-serif;
  font-weight: normal;  
}
.btn-primary,
#close-process a.btn-default { 
  border-color: #ff931e;
  color: #000;
  background: #ffbb72; /* Old browsers */
  background: -moz-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffbb72), color-stop(100%,#ff931e)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffbb72 0%,#ff931e 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffbb72 0%,#ff931e 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffbb72 0%,#ff931e 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffbb72 0%,#ff931e 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffbb72', endColorstr='#ff931e',GradientType=0 ); /* IE6-9 */
}
#close-process.close-process-scroll{
    margin-top:38px;
}
.btn-primary .using-glyphicons{
  color:#fff;
}
button, input, optgroup, select, textarea,
.bootstrap-select button,
.inner.selectpicker > li > a:hover,
.inner.selectpicker > li > a:focus{
   font-family: Verdana, Arial, sans-serif;
}
#close-process a.btn-default{
  color:#fff;
}
.btn-primary:hover,
#close-process a.btn-default:hover{
  color: #fff;
  border-color:#ff931e;
  background: #ff931e; /* Old browsers */
  background: -moz-linear-gradient(top, #ff931e 0%, #ffbb72 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ff931e), color-stop(100%,#ffbb72)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ff931e 0%,#ffbb72 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ff931e 0%,#ffbb72 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ff931e 0%,#ffbb72 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ff931e 0%,#ffbb72 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff931e', endColorstr='#ffbb72',GradientType=0 ); /* IE6-9 */
}
.btn-default
{
  border-color: #ff931e;
  color: #000;
  background: #dedede; /* Old browsers */
  background: -moz-linear-gradient(top, #dedede 0%, #bababa 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dedede), color-stop(100%,#bababa)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #dedede 0%,#bababa 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #dedede 0%,#bababa 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #dedede 0%,#bababa 100%); /* IE10+ */
  background: linear-gradient(to bottom, #dedede 0%,#bababa 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dedede', endColorstr='#bababa',GradientType=0 ); /* IE6-9 */
}
.btn-default:hover
{
  border-color:#ff931e;
  background: #bababa; /* Old browsers */
  background: -moz-linear-gradient(top, #bababa 0%, #dedede 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#bababa), color-stop(100%,#dedede)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #bababa 0%,#dedede 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #bababa 0%,#dedede 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #bababa 0%,#dedede 100%); /* IE10+ */
  background: linear-gradient(to bottom, #bababa 0%,#dedede 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bababa', endColorstr='#dedede',GradientType=0 ); /* IE6-9 */
}
.selectpicker.btn-default {
 
    font-size: 12px;
   height:27px;
   padding-top: 0;
   padding-bottom: 0;
}

.input-group-addon.withButton {
	padding: 0px;
	
	.btn-primary {
		padding-top: 0px;
		padding-bottom: 0px;
		font-size: 15px;
		height: 25px;		
	}
}	

