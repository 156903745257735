#serviceStatus{
    display:table;
    width:100%;
    margin: 30px 0;
    
}

.service-status-header{
    display:table-row;
}
.service-status{
    display:table-cell;
    width: 16.66%;
    background: #E0E4E8;
    font-size:16px;
    font-weight:bold;
    border-right: 1px solid #FFF;
}
.serviceStatus:last-child{
    border: none;
}
.service-status-pic{
    height: 48px;
}
.service-status.finished{
    background-color: #5E7386;
    color:#FFF;
}
.finished .service-status-pic{
    background: transparent url(../../images/de_DE/01_status-icon_done.png) no-repeat center center; 
}
.service-status.active{
    background-color: #FF8A16;
    color:#FFF;
}
.active .service-status-pic{
    background: transparent url(../../images/de_DE/02_status-icon_processing.png) no-repeat center center;
}
.service-status-pic{
    background: transparent url(../../images/de_DE/03_status-icon_ahead.png) no-repeat center center;
}
.service-status-text{
    padding: 0 10px 10px;
    text-align: center;
    height: auto;
}

@media (max-width: 699px){
    #serviceStatus,
    .service-status-header{
        display:block;
    }
    .service-status{
        display:block;
        width:100%;
        margin-bottom:10px;
        height:48px;
        border: none;
    }

    .service-status-pic,
    .service-status-text{
        display: inline-block;
        height:48px;
    }
    .service-status-pic{
        width:48px;
    }
    .service-status-text{
        padding:11px;
        width:80%;
        text-align:left;
        vertical-align:top;
    }
}