@screen-xs-max: 991px; // = bootstrap screen-lg-min -1 , lg in ffo ab 992
@screen-original-xs-max: 767px; // = bootstrap screen-sm-min -1

@media (max-width: 1030px) {
    #headMenu #logout .hidden-xs{
        display:none;
    }
}

@media (max-width: 930px) {
    header{
        background-image:none;
        background: #fff;
        height:45px;
    }

    div#logo{
        display:none;
    }
    div#branches{
        float:left;
        background: url("../../images/de_DE/SWS_www_piktogramme.png") no-repeat scroll right top / 100% auto rgba(0, 0, 0, 0);
        margin: 5px 10px 0px 15px;
        width:150px;
        height: 42px;
    }

    div#direktservice{
        float:left;
        margin: 13px 0px 0px 16px;
        background-size:80%;
        height: 32px;
    }
    div#sideNav {
        position: fixed;
        top: 170px;
    }

    div#headMenu{
        position: relative;
        //top:-45px;


        .dropdown a {
            color:#ff8800;
        }
        a.dropdown-toggle + .dropdown-menu {
            margin-top: 6px;
        }
        a.dropdown-toggle, span{
            color: #ff8800;
            .using-glyphicons{
                color:#ff8800;
            }
        }

        .postbox-badge {
            background-color: #ff8800;
            color: #fff;
        }
        #head-menu-postbox-link .using-glyphicons,
        #head-menu-logout-icon{
            color:#ff8800;
        } 
        .head-menu-postbox-title{
            display:none;
        }
        .top-menu-postbox{
            width:55px;
        }
        #top-drop-menu{
            width:90%;
        }
    }
    div#menu-bg-line div#header-bg-container {
        height: 87px;
    }

    div#topMenu .container-fluid > #itc-navbar-collapse{
        padding-left: 4px;
    }
    div#topMenu .homeLinkContainer{

    }
    /*     #headMenu .col-xs-12{
            padding:0px;
            width:33%;
        }
        #headMenu .col-xs-12:first-child{
            width:30%;
        }*/
}

@media (max-width: @screen-original-xs-max) {

	div#sideNav {
	  position: fixed;
	  top: 170px;
	}  

    div#headMenu.fixed{
        .col-xs-12{
            width:30%;
        }
        .col-xs-12:first-child{
            width:70%;
        }
    }
    div#logo{
        height:48px;
        width:130px;
        margin-top:3px;
        margin-left: 10px;
    }
    .navbar .navbar-header{
        border-bottom:none;
    } 
    .dropdown {
        margin-right: 0px;
    }
    .form-group-panel-default .form-group .key-label {
        margin-left: 0px;
    }
    #topMenu .navbar-nav > li:first-child > a.dropdown-toggle{
        border-top: 1px solid #96a6b3;
    }
    div#topMenu .homeLinkContainer{
    } 
    div#topMenu #miniature-context-2-lines,
    div#topMenu #miniature-context-3-lines{
        width:65%;
        float:left;
        margin:0;
    }
    .home-top-menu-icon{
        height:38px;
    }
    div#topMenu .homeLinkContainer{
        margin-right:11px;
    }
    .form-group-panel-default .form-group .key-label {
        margin-left: -15px;
    }
    #deliveryDate .value-label{
        margin-left:-10px;
    }
	
	#headMenu .col-xs-12:last-child {
        padding-right: 0px;
    }
}
@media (max-width: 699px) {

    #productSearchForm .pull-right.text-transform-normal{
        display:block;
        float:none !important;
        padding-left:26px;
    }
}

@media (max-width: 590px) {
    footer{
        overflow:visible;
    }

    footer .navbar-nav > li > a{
        padding: 3px 5px 3px 10px;
    }
    #headMenu a.dropdown-toggle{
        margin-left:0;
    }

    div#headMenu #logout #head-menu-logout-icon, div#headMenu #home-link #head-menu-logout-icon {
        //margin-left: 10px;
    }

    #branches{
        display:block;
    }
    .navbar-brand{
        font-size:15px;
    }
}

@media (max-width: 530px) {
    div#direktservice {
        display:none;
    }
    #headMenu.fixed .direktserviceScroll{
        display:none;
    }
    #headMenu .col-xs-12 {
        //padding: 0;
        //width: 50%;
    }
    #headMenu .col-xs-12:last-child {
        width: auto;
		padding-left: 0px;
    }
}
@media (max-width: 462px) {
    .navbar-brand{
        padding-top:4px;
    }
	 div#headMenu.fixed{
        .col-xs-12{
            width:50%;
        }
        .col-xs-12:first-child{
            width:50%;
        }
    }
}

@media (max-width: 430px) {
    div#topMenu #miniature-context-2-lines,
    div#topMenu #miniature-context-3-lines{
        width:50%;  
    }
}
