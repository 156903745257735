
#widget-layout-container .panel {
    border-radius: 0px;
}

#widget-layout-container .panel-default {
    border: 0px;
	box-shadow: none;
}

#widget-layout-container .panel-heading {

	border-radius: 0px;   
	
	> h3.panel-title {
	  
	  padding-right: 0%;
				
		> a.openDetails {
			background: url(../../images/de_DE/svg/icon-details.svg) no-repeat center center;
			background-size: 100%;	
			display: block;
			float: right;			
			height: 25px;
			width: 25px;
			margin-top: -4px;
			margin-right: -10px;			
		}	
	}
}

#widget-layout-container .panel-body {    
    position: relative;
	
	> .widget-formline-text {
		font-size: 100%;
	}
	
	.radio-inline, .checkbox-inline {	
		margin-bottom: -15px;
		margin-top: -15px;
		
		> .prettyradio > label, > .prettycheckbox > label {
			font-weight: normal;
		}
	}
	
	.widget-meter-value-container {
		*.col-xs-24,
		*.col-xs-23,
		*.col-xs-22,
		*.col-xs-21,
		*.col-xs-20,
		*.col-xs-19,
		*.col-xs-18,
		*.col-xs-17,
		*.col-xs-16,
		*.col-xs-15,
		*.col-xs-14,
		*.col-xs-13,
		*.col-xs-12,
		*.col-xs-11,
		*.col-xs-10,
		*.col-xs-9,
		*.col-xs-8,
		*.col-xs-7,
		*.col-xs-6,
		*.col-xs-5,
		*.col-xs-4,
		*.col-xs-3,
		*.col-xs-2,
		*.col-xs-1  {
			padding-left: 0px;
			padding-right: 0px;
		}

		clear: both;
		float: left;
		margin-bottom: 10px;
		width: 100%;
		
		.mv-type {
			background-color: #E0E4E8;
			text-align: center;
			float: left;
		}
		.mv-pre-comma {
			background-color: @std-color;
			border-left: 1px solid #FFFFFF;
			color: #FFFFFF;
			text-align: center;
		}
		.mv-pre-comma.not-available {
			background-color: @std-color;
			color: #FFFFFF;
		}
		.mv-comma {
			background-color: #E0E4E8;
			border-left: 1px solid #FFFFFF;
			text-align: center;
		}
		.mv-post-comma {
			background-color: #E0E4E8;
			border-left: 1px solid #FFFFFF;
			text-align: center;
		}
		.mv-unit {
			background-color: #E0E4E8;
			border-left: 1px solid #FFFFFF;		
			text-align: center;
		}		
	}	
}

.tab-pane {
	.icon-strong-meter {
		margin-left: 6px;
	}
}


.teaser-panel {
	margin-bottom: 5px;
}


#accordion.panel-group {
	.fa {
		width: 27px;
		color: #EA7000;
		text-align: center;
	}
}


#process-layout-container.with-marginal-column {
	max-width: 1280px;
	
	#close-process {
		right: 340px;
	}

	#process-fluid-panel {
		float: left;
		width: 100%;
		
		.panel.panel-default {
			margin-right: 320px;
		}	
	}	
}
#process-layout-container.with-marginal-column #marginal-column {
	float: right;
	width: 300px;
	margin-left: -320px;
	
	.panel-body {
		padding-top: 0px;
		padding-bottom: 0px;
		
		.form-group {
			margin-bottom: 0px;
			padding-bottom: 5px;
		}
		.form-group.highlight {
			background-color: #F9F9F9;
			color: #485f73;
		}	
	}

	.control-label {
		text-align: left;
		padding-top: 5px;
	}

	.value-label {
		padding-top: 5px;
	}

	#marginal-contact .using-glyphicons {
		color: #485f73;
	}

	#marginal-contact .img-responsive {
		display: none;
	}	
}			
