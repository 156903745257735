#serviceStatus {
  display: table;
  width: 100%;
  margin: 15px 0;

  span.popover-tooltip {
    background-color: transparent;
  }
}

.service-status-header {
  display: table-row;
}

.service-status {
  display: table-cell;
  width: 14.28%;
  background: #F0F0F0;
  font-size: 12px;
  font-weight: bold;
  border-right: 1px solid #FFF;
}

.serviceStatus:last-child {
  border: none;
}

.service-status-pic {
  height: 48px;
}

.service-status.finished, table.contractStatusTable td.finished {
  background-color: #00A817 !important;
  color: #FFF;
}

.finished .service-status-pic {
  background: transparent url(../../images/de_DE/01_status-icon_done.png) no-repeat center center;
}

.service-status.active, table.contractStatusTable td.active {
  background-color: #ff8800 !important;
  color: #FFF;
}

.active .service-status-pic {
  background: transparent url(../../images/de_DE/02_status-icon_processing.png) no-repeat center center;
}

.error .service-status-pic {
  background: transparent url(../../images/de_DE/02_status-icon_processing.png) no-repeat center center;
}

.service-status-pic {
  background: transparent url(../../images/de_DE/03_status-icon_ahead.png) no-repeat center center;
}

.service-status-text {
  padding: 0 10px 10px;
  text-align: center;
  height: auto;
}

.service-status.error, table.contractStatusTable td.error {
  background-color: #ff2500 !important;
  color: #FFF;
}

div.row.prospect-postbox {
  div.prospect-postbox-table {
    display: table;
    div.prospect-postbox-table-row {
      display: table-row;
      div.prospect-postbox-table-cell {
        display: table-cell;
        padding-left: 5px;
        padding-bottom: 10px;
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          text-align: right;
          line-height: 8px;
        }
      }
      &:last-child {
        div.prospect-postbox-table-cell {
          padding-bottom: 0px;
        }
      }
    }
  }
}

@media (max-width: 699px) {
  #serviceStatus,
  .service-status-header {
    display: block;
  }

  .service-status {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    height: 48px;
    border: none;
  }

  .service-status-pic,
  .service-status-text {
    display: inline-block;
    height: 48px;
  }

  .service-status-pic {
    width: 48px;
  }

  .service-status-text {
    padding: 11px;
    width: auto;
    text-align: left;
    vertical-align: top;
    padding-top: 16px;
  }
}