.barzahlen-quad-table{
    margin:0;
    margin-bottom: 15px;
    .display-table {
        display: table;
        width: 100%;
        .display-table-row {
            display: table-row;
            .display-table-cell {
                display: table-cell;
                width: 50%;
                vertical-align: top;
                background-color: #F8F8F7;
                border: 15px solid #F2F2F2;
                padding-bottom: 15px;

                ol {
                    margin-left: -25px;
                    li {
                        margin-bottom: 10px;
                    }
                }
                ul {
                    list-style-image: url(images/greenhaken.png);
                    margin-left: -17px;
                }
                .ref-logo {
                    margin-right: 10px;
                    margin-top: 10px;
                }
            }
            .display-table-cell:last-child {
                border-left-width: 7.5px;
            }
            .display-table-cell:first-child {
                border-right-width: 7.5px;
            }
        }
    }
}

.barzahlen-quad-table .display-table:first-child  .display-table-row .display-table-cell{
    border-bottom-width: 0;
}
.text-center {
    text-align: center !important;
}
#barzahlenMap{
    width:100%;
    max-width:352px;
}
.widget-bottom-container a.no-icon{
    padding:0;
    background: none;
}
.center{
    height:50px;
}
.accept-conditions-table .alert-danger{
    background-color: #FFF;
}
#logo1{
    height:40px;
}
.link-left{
    text-align:left !important;
    display:block;
    padding-left:8px;
    margin-left:5px;
}
#barzahlen-logo{
    margin: 0;
    padding-left:90px;
    height:72px;
    display:block;
}

.widget-container.cash {
	.col-xs-16 img#login-key {
		padding-left: 70px;
	}
}

body.in .widget-container.cash .center {
    margin-top: 0 !important;
}
