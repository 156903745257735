
.table.products-table {

	td.has-pretty-child {
		padding-bottom: 2px;
		width: 30px;
		
		.prettyradio, .prettycheckbox {
			margin-left: 0px;
		}
		
		.prettycheckbox label, .prettyradio label {
			display: none;
		}
	}

	td span, td a.form-link {
		display: inline-block;
		margin-top: 4px;
	}	
}

.table-collapse-row td {
  background-color: #F3F4F6 !important;
}

.table-row-collapse-btn {
  float: right;
  padding: 0px 12px;
}

.table-responsive .dt-responsive {
  width: 100% !important;
}


table.dataTable {
  margin-top: 0px !important;
  border: 1px solid #E0E4E8;
  border-left: 2px solid #E0E4E8;
  
  > thead > tr > th {
    background-color: #f3f4f6;
    border-left: 1px solid #e0e4e8 !important;
    border-right: 0px !important;
    border-bottom: 1px solid #9FABB7 !important;
    
    &:first-child {
      border-left: 0px !important;
    }
  }
  
  > thead > tr > th.dataTableDateColumn {
    width: 60px;
  }  
  
  > tbody > tr > td {
    border-left: 1px solid #e0e4e8 !important;
    border-bottom: 1px solid #e0e4e8 !important;
    border-right: 0px;
    border-top: 0px;
    
    &:first-child {
      border-left: 0px !important;
    } 
    
    &:last-child {
      a {
          &:hover, &:focus {
            text-decoration: none !important;
          }    
        }
        
      a.form-link {
        &:hover, &:focus {
          text-decoration: underline !important;
        }
      } 
    }   
  }
  
  > tbody > tr {
     
     //font-weight: bold;
      
    &:last-child {
      td {
        border-bottom: 0px !important;       
      }  
    }    
  }
  
  > tbody {
    tr.read {
      font-weight: normal;
      
      td {
        
        &:first-child .using-glyphicons {
          color: #CACFD4; 
        }  
        
        .using-glyphicons {
          font-weight: bold;
        }  
       
        &:last-child {
          color: #485f73;          
        }
      } 
    }
  }  
}

table.dataTable.postbox {
  
  border-bottom: 1px solid #96a6b3 !important;
  
  thead tr th {
    &:first-child {
      padding-right: 25px;
      width: 1px !important;
    }
    &:last-child {
      width: 1px !important;
    }
  }
  
  > tbody > tr {
     
     font-weight: bold;
  }
  
  > tbody > tr.read {
     
     font-weight: normal;
  }      
  
  td {
    
    border-bottom: 1px solid #96a6b3 !important;
    
    .postboxTableMessageContent {
      font-family: 'PT-Sans';
      font-weight: normal;
      background-color: #F9F9F9;
      margin: -8px;
      padding: 8px;
      //border-bottom: 1px solid #96a6b3;
      
      ul {
        margin-left: -23px;    
      }
      
      h3 {
        font-size: 16px;
        border-color: #e0e4e8;
        padding-bottom: 5px;
      }         
    }
    
    div.datatable-subject-table {
      display: table;
      width: 100%;
      
      div.datatable-subject-table-row {
        display: table-row;
        
        div.datatable-subject-table-cell {
          display: table-cell;
          
          &:first-child {
            padding-right: 10px;
          }
          
          div.attachment {
            text-align: right;
            font-family: 'Glyphicons Halflings';
            font-weight: normal;
          }
        }
      }  
    }  
  }
  
  td.open-message-border {
    border-bottom-color: #e0e4e8 !important;
  }  
}

.pagination > li.active > a {
  background-color: #5E7386;
  border-color: #5E7386;
  font-family: 'PT-Sans-bold';
  
  &:hover, &:focus {
    background-color: #5E7386;
    border-color: #5E7386;
    font-family: 'PT-Sans-bold' !important;
  }  
}

.pagination > li > a {
  &:hover, &:focus {
    background-color: #E0E4E8;
  }  
}
.pagination > li.paginate_button a {
  &:hover, &:focus {
    font-family: 'PT-Sans';
  }
}
