/*Schriften*/
//@import "swsn-variables.less";

.font-bold {
  font-family: Verdana, Arial, sans-serif;
  font-weight: bold;
  color: #4d4d4d;
}

.font-normal {
  font-family: Verdana, Arial, sans-serif !important;
  font-weight: normal !important;
}

.using-glyphicons {
  color: #4d4d4d;
  font-family: "Glyphicons Halflings";
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6,
.panel-body h3 {
  font-family: Verdana, Arial, sans-serif;
  border-color: #ccc;
}

h3 {
  color: #0164a8;
  margin-top: 30px;
}

hr {
  border-color: #ccc;
}

a {
  color: #ff8800;
  text-decoration: none;

  &:hover, &:focus {
    color: #ff8800;

  }
}

/*Regionen*/

body {
  color: #4D4D4D;
  background: url("../../images/de_DE/itc-background.jpg") no-repeat fixed 0 0;
  font-family: Verdana, Arial, sans-serif;
  font-size: 12px;
  line-height: 1.42857;
  background-size: cover;
  -ms-overflow-style: scrollbar;
}

body.poco-cockpit-out #topMenu {
  position: relative;
  top: 0px;
}

body.poco-cockpit-out div#topMenu.fixed {
  position: fixed;
}

body.poco-cockpit.in #topMenu {
  top: -40px;
}

header {
  background: url("../../images/de_DE/SWS_HEADER.jpg") no-repeat 0 -8px;
  height: 130px;
}

.poco-cockpit-out #main {
  top: 0px;
}

div#branches {
  background: url("../../images/de_DE/SWS_www_piktogramme.png") no-repeat scroll right top / auto auto rgba(0, 0, 0, 0);

  margin-right: 23px;
  margin-top: 23px;
}

div#logo {
  height: 69px;
  width: 192px;
  background: url("../../images/de_DE/itc-ag-logo.png") no-repeat;
  float: left;
  margin-left: 21px;
  margin-top: 14px;
}

div#direktservice {
  background: url("../../images/de_DE/svg/direktservice_typo.png") no-repeat;
  float: right;
  height: 50px;
  margin: 37px 10px 0px 8px;
  width: 143px;
}

footer {
  background-image: url("../../images/de_DE/footer_back.jpg");
  background-repeat: repeat-y;
  background-position: center top;
  position: fixed;
  border-top: none;
  bottom: 0px;
  min-height: 28px;
  height: 28px;
  z-index: 9999;

  ul {
    width: 100%;
  }

  .navbar-nav {
    padding-left: 3px;

    li > a {
      color: #fff;
      padding-top: 3px;
      padding-bottom: 3px;
      font-size: 12px;

      &:hover, &:focus {
        background-color: transparent;
        color: #fff;
      }
    }

    li::after {
      content: "|";
      color: #fff;
      position: relative;
      top: -22px;
    }

    li:first-child::after {
      content: "";
    }
  }
}

div#menu-bg-line {
  border-bottom: none;
  background-color: transparent;
}

div#headMenu {
  background-color: transparent;
  border-top: 0px solid #c9c9c9;
  height: 40px;
  padding-right: 12px;
  position: relative;
  top: -45px;
  z-index: 9999;
  font-size: 16px;

  a.dropdown-toggle, span {
    color: #fff;

    .using-glyphicons {
      color: #fff;
    }
  }

  a.dropdown-toggle + .dropdown-menu {
    margin-top: 6px;
  }

  .postbox-badge {
    background-color: #fff;
    color: #ff8800;
    margin-right: 5px;
    margin-top: -4px;
    margin-left: 2px;
  }

  div:first-child {
    padding: 0;
  }

  #head-menu-postbox-link .using-glyphicons,
  #head-menu-logout-icon {
    color: #fff;
  }

  a.dropdown-toggle .caret {
    margin-top: 7px;
  }

  .col-xs-12:first-child {
    width: 0;
  }
}

div#headMenu.fixed {
  background-color: #fff;
  height: 45px;

  .dropdown a {
    color: #ff8800;
  }

  .col-xs-12:first-child {
    width: 50%;
  }

  a.dropdown-toggle, span {
    color: #ff8800;

    .using-glyphicons {
      color: #ff8800;
    }
  }

  .postbox-badge {
    background-color: #ff8800;
    color: #fff;
  }

  #head-menu-postbox-link .using-glyphicons,
  #head-menu-logout-icon {
    color: #ff8800;
  }
}

#home-link {
  margin-left: 18px;
}

.navbar-default {
  background: #0366a9; /* Old browsers */
  background: -moz-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0366a9), color-stop(100%, #3a87ba)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* IE10+ */
  background: linear-gradient(to bottom, #0366a9 0%, #3a87ba 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0366a9', endColorstr='#3a87ba', GradientType=0); /* IE6-9 */
}

.open > .dropdown-menu > li a {
  border-bottom: 1px solid #c9c9c9;
  color: #4d4d4d;

  &:hover, &:focus {
    border-left: 6px solid #ff931e;
    background-color: #f0f0f0;
  }
}

div#topMenu {
  position: relative;

  .navbar-nav > li > a {
    padding: 10px 10px 10px 15px;
    display: block;
    float: none;
  }

  .navbar {
    min-height: 40px;
  }
}

#topMenu .navbar-default .navbar-nav > .open > a {
  background-color: #3081b8 !important;
}

#topMenu .open .dropdown-menu li a {
  color: #4d4d4d;
}

#topMenu .navbar-collapse .navbar-nav > li > a.dropdown-toggle:hover {
  background-color: #3081b8 !important;
}

#topMenu .navbar-nav > li > a.dropdown-toggle.no-toggle:hover {
  color: #fff !important;
}

#topMenu .navbar-nav > li > a.dropdown-toggle {
  color: #fff;
}

body.poco-cockpit.in div#topMenu.fixed-with-headmenu {
  border-bottom: none;
  height: 40px;
  top: 45px;
}

.navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
.navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
  background-color: #f0f0f0;
  color: #4d4d4d;
}

#itc-navbar-collapse .navbar-nav > li > a {
  font-weight: normal;
  font-size: 16px;
  background: #0366a9; /* Old browsers */
  background: -moz-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #0366a9), color-stop(100%, #3a87ba)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #0366a9 0%, #3a87ba 100%); /* IE10+ */
  background: linear-gradient(to bottom, #0366a9 0%, #3a87ba 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0366a9', endColorstr='#3a87ba', GradientType=0); /* IE6-9 */
  color: #fff;
}

.navbar-toggle {
  margin-left: 19px;
  border-color: #ff931e;
  color: #000;
  background: #ffbb72; /* Old browsers */
  background: -moz-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffbb72), color-stop(100%, #ff931e)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffbb72 0%, #ff931e 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbb72', endColorstr='#ff931e', GradientType=0); /* IE6-9 */

  &:hover, &:focus {
    background: #ff931e; /* Old browsers */
    background: -moz-linear-gradient(top, #ff931e 0%, #ffbb72 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ff931e), color-stop(100%, #ffbb72)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #ff931e 0%, #ffbb72 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #ff931e 0%, #ffbb72 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #ff931e 0%, #ffbb72 100%); /* IE10+ */
    background: linear-gradient(to bottom, #ff931e 0%, #ffbb72 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff931e', endColorstr='#ffbb72', GradientType=0); /* IE6-9 */
  }
}

.navbar-nav {
  margin-bottom: 0px;
}

.labelNoPadding {
  padding-right: 0px;
}

#quick-links li {
  background: rgba(0, 0, 0, 0) url("../../images/de_DE/icon-link.png") no-repeat scroll 0 0px;

  a {
    color: #ff8800;
  }
}

.invoice-pdf-link, .invoice-pdf-link:hover, .invoice-pdf-link:focus {
  color: #ff8800;
}

/*Widgets*/

#login-key {
  width: 82px;
}

#login-logo {
  margin-top: -5px;
}

body.poco-cockpit.in #widget-layout-container {
  margin-top: -40px;
}

body.poco-cockpit.in #process-layout-container {
  margin-top: -30px;
}

.widget-container,
.in .widget-container {
  height: 319px;
  border: 1px solid #ccc;

  .panel-body {
    height: 200px;
  }
}

.widget-bottom-container a {
  background: url("../../images/de_DE/icon-link.png") no-repeat scroll 0px 0px rgba(0, 0, 0, 0);
  color: #ff8800;
  display: block;
  height: 17px;
}

.swiper-container .widget-bottom-container {
  bottom: 0;
}

.widget-bottom-container a.inactive {
  background: url("../../images/de_DE/icon-link-inactive.png") no-repeat scroll 0px 0px rgba(0, 0, 0, 0);
  color: #ababab;
  cursor: default;
  float: left;
  padding-right: 6px;
}

.widget-container.quick-links {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-direct-link.svg");
}

.widget-container.customer {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-customer.svg");
}

.widget-container.meter.power {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-branch-energy.svg");
  background-size: 75% auto;
  background-position: center 70px;
}

.widget-container.meter.gas {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-branch-gas.svg");
  background-size: 75% auto;
  background-position: center 70px;
}

.widget-container.meter.heating {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-branch-heating.svg");
  background-size: 75% auto;
  background-position: center 70px;
}

.widget-container.meter.water {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-branch-water.svg");
  background-size: 75% auto;
  background-position: center 70px;
}

.widget-container.prepayment {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-prepayment.svg");
}

.widget-container.customer-account {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-calculator.svg");
}

.widget-container.invoice {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-invoice.svg");
}

.widget-container.postbox {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-postbox.svg");
}

.widget-container.bankdata {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-bankdata.svg");
}

.widget-container.contract {
  background-image: url("../../images/de_DE/svg/icon-highlight-bg-contract.svg");
}

.widget-container.move {
  background: #FFF url("../../images/de_DE/svg/icon-highlight-bg-move.svg") no-repeat;
  background-position: center 95px;
  background-size: 65%;
}

.widget-container.postbox .postbox-entry a.widget-postbox-link {
  font-family: Verdana, Arial, sans-serif;
  color: #ff8800;
}

.widget-container.postbox .postbox-entry.read a.widget-postbox-link {
  font-family: Verdana, Arial, sans-serif;
  color: #FFA954;
  height: 30px;
}

.widget-container.postbox .postbox-entry a.widget-postbox-link .widget-postbox-subject {
  float: left;
  width: 138px;
}

.widget-container.postbox .postbox-entry a.widget-postbox-link .widget-postbox-link-icon {
  margin-top: 0px;
}

#widget-layout-container .panel-heading > h3.panel-title > a.openDetails {
  background: url("../../images/de_DE/icon-widget-details.png");
}

#widget-layout-container .panel-heading > h3.panel-title > a.openDetails {
  width: 39px;
  height: 39px;
  margin-right: -15px;
  margin-top: -9px;
}

.widget-container.postbox .postbox-entry a.widget-postbox-link .widget-postbox-link-icon {
  font-size: 12px;
}

.multi-widget span.content-switch span.content-switch-row span.content-switch-cell span.content-switch-prev,
.multi-widget span.content-switch span.content-switch-row span.content-switch-cell span.content-switch-next {
  color: #ff931e;
}

.multi-widget span.content-switch span.content-switch-row span.content-switch-cell span.current-switch {
  color: #FFF;
}

.multi-widget span.content-switch span.content-switch-row span.content-switch-cell span.total-switch {
  color: #FFF;
}

#widget-layout-container .panel-body .widget-meter-value-container .mv-pre-comma,
#widget-layout-container .panel-body .widget-meter-value-container .mv-pre-comma.not-available,
.mv-pre-comma {
  background-color: #0164a8;
  color: #fff;
}

#widget-layout-container .panel-body .widget-meter-value-container .mv-post-comma,
#widget-layout-container .panel-body .widget-meter-value-container .mv-unit,
#widget-layout-container .panel-body .widget-meter-value-container .mv-comma,
#widget-layout-container .panel-body .widget-meter-value-container .mv-type {
  background-color: #999;
  color: #fff;
}

.widget-container.contract img #widgetContractMediaTypeIcon {
  height: 30px;
  margin-top: -5px;
}

.widget-container .row.mt--10 {
  height: 68px;
}

#customerWidget .alert-default {

  margin-top: 4px;
  float: right;
}

#customerWidget .widget-bottom-container a.inactive,
#customerWidget .widget-bottom-container a {
  float: left;
  width: 248px;
}

#widget-layout-container .panel-title {
  color: #fff;
}

.slider-handle {
  border-color: #ff931e;
  background: #ffbb72; /* Old browsers */
  background: -moz-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #ffbb72), color-stop(100%, #ff931e)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #ffbb72 0%, #ff931e 100%); /* IE10+ */
  background: linear-gradient(to bottom, #ffbb72 0%, #ff931e 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbb72', endColorstr='#ff931e', GradientType=0); /* IE6-9 */
}

/*Prozesse*/

.panel-default > .panel-heading {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#97bfdc+0,69a4cc+100 */
  background: #97bfdc; /* Old browsers */
  background: -moz-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #97bfdc), color-stop(100%, #69a4cc)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* IE10+ */
  background: linear-gradient(to bottom, #97bfdc 0%, #69a4cc 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#97bfdc', endColorstr='#69a4cc', GradientType=0); /* IE6-9 */
  font-family: Verdana, Arial, sans-serif;
}

.panel-title {
  color: #fff;
}

.form-control {
  border: 1px solid #7f8080;
  color: #000;
  height: 27px;
  font-size: 12px;
}

.form-group-panel-default .form-group .key-label {
  font-family: Verdana, Arial, sans-serif;
  font-weight: normal;

}

.form-group-panel-default .form-group .value-label {
  margin-left: 0px;
}

.control-label.text-left {
  font-family: Verdana, Arial, sans-serif;
  font-weight: normal;
}

.alert-danger,
.form-group-panel-default .form-group .alert-danger {
  color: #ff0000;
}

.form-group label {
  border: none !important;
}

.input-group-addon {
  padding: 0 12px;
}

.input-group-addon.alert-danger {
  color: #ff0000;
  background-color: #fce6e6;
  border-color: #ff0000;
  border-left: none;
}

.alert-info, .alert-info:focus,
.input-group.alert-info .bootstrap-select.form-control button {
  border-color: #ff931e;
  color: #ff931e;
  background-color: #fff8ea;
}

.selectable {
  border-top: none;
  border-right: none;
  border-bottom: none;
}

.input-group-addon {
  background-color: transparent;
  border-color: #7f8080;
}

.input-group.alert-danger .bootstrap-select.form-control button {
  background-color: #ffeadf;
  color: #ff0000;
}

.bootstrap-select .input-group-btn span {
  border-top: none;
}

.alert-default,
.date.input-group-addon,
.input-group-addon .alert-info {
  border-color: #7f8080;
  background-color: #fff8ea;
}

.calendar {
  border-color: #ff931e;
  background-color: #fff8ea;
}

.alert-danger {
  border-color: #ff0000;
}

span.popover-tooltip.alert-info {
  border-color: #ff931e;
  background-color: #fff8ea;
}

.panel-default > .panel-heading {
  border-color: #ccc;
}

.widget-container .panel-default > .panel-heading {
  border-bottom: 1px solid #f38212;
}

.panel-default {
  border-color: #ccc;
}

.panel-default .panel-default {
  border-color: #ccc;
}

.nav-tabs {
  border-bottom: 1px solid #ccc;
}

.tab-pane {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus,
.nav-tabs > li > a {
  border-color: #ccc #ccc transparent;
  color: #0164a8;
  font-family: Verdana, Arial, sans-serif;
  font-weight: bold;
}

.nav-tabs > li > a:hover {
  border-color: #ccc #ccc #fff #ccc;
}

.form-group-panel-default .form-group .value-label {
  font-family: Verdana, Arial, sans-serif;
  font-weight: normal;
  color: #4d4d4d;
}

a.form-link {
  background: url("../../images/de_DE/icon-link.png") no-repeat scroll 0px 0px rgba(0, 0, 0, 0);
  color: #ff8800;
  display: block;
}

#invoiceAddressUpdateToCustomerRequestLink,
#customerUpdateLink,
#customerUpdateLinkFromContract,
#customerRequestLinkFromContract {
  padding-left: 23px;
}

#customerRequestLinkFromContract,
#customerUpdateLinkFromContract,
#invoiceAddressUpdateToCustomerRequestLink {
  background: url("../../images/de_DE/icon-link.png") no-repeat scroll 0px 0px rgba(0, 0, 0, 0);
}

a.form-link.inactive {
  background: url("../../images/de_DE/icon-link-inactive.png") no-repeat scroll 0 0px rgba(0, 0, 0, 0);
  color: #9fabb7;
  cursor: default;
}

.panel-heading {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

span.step {
  background-color: #c9c9c9;
}

span.step.active {
  background-color: #0064a8;
}

/*Tabellen*/

.table > thead > tr > th {
  background-color: #ccc;
  border-bottom: 1px solid #4d4d4d;
}

.table-striped > tbody > tr:nth-child(2n) > td, .table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #f0f0f0;
}

table, td {
  border: 1px solid #c9c9c9;
}

.table-responsive {
  border-left: 1px solid #c9c9c9;
  border-bottom: 1px solid #c9c9c9;
  border-right: none;
  border-top: 1px solid #4d4d4d;
}

table th a {
  color: #4d4d4d;
}

#postboxTable_wrapper .using-glyphicons {
  font-size: 17px;
}

#postboxTable tr td {
  font-weight: bold;
}

#postboxTable tr.read td {
  font-weight: normal;
}

#customerUpdateForm .control-label {
  text-align: left;
}

/*Kalender*/
.datepicker {
  z-index: 1000;
}

.datepicker td.day.old {
  color: #a3a3a3;
}

.datepicker th.prev, .datepicker th.datepicker-switch, .datepicker th.next {
  background-color: #ffce9a;
  color: #4d4d4d;
}

.datepicker thead tr:first-child th:hover {
  background-color: #ff931e;
  color: #fff;
}

.datepicker td, .datepicker td span.month, .datepicker td span.year {
  color: #4d4d4d;
}

.datepicker td.day:hover {
  background-color: #ffce9a;
}

.datepicker td.today, .datepicker td.today:hover, .datepicker td.active:hover,
.datepicker td.active:hover:hover, .datepicker td.active:focus, .datepicker td.active:hover:focus,
.datepicker td.active:active, .datepicker td.active:hover:active, .datepicker td.active.active,
.datepicker td.active.active:hover, .datepicker td.active.disabled, .datepicker td.active.disabled:hover, .datepicker td.active[disabled], .datepicker td.active[disabled]:hover, .datepicker td span.active:hover, .datepicker td span.active:focus,
.datepicker td span.active:active, .datepicker td span.active.active, .datepicker td span.active.disabled, .datepicker td span.active[disabled] {
  background-color: #ff931e;
}

.datepicker .table-condensed td .day:hover,
.datepicker td span.month:hover,
.datepicker td span.year:hover {
  background-color: #ffce9a !important;
}

/*Product Price Container*/
.tariff-flex-container.forWaste {
    display: flex;
}
.forWaste .tariff-container{
    display: flex;
    flex-direction: column;
}
.forWaste .tariff-container > .panel-default{
    height: 100%;
    position: relative;
    padding-bottom: 46px;
}
.forWaste .tariff-container > .panel-default .col-xs-24{
    position: inherit;
}
.forWaste .tariff-container > .panel-default button{
    position: absolute;
    bottom: 15px;
    left: 15px;
}
.wasteSelection a {
  width: 25px !important;
}
#wasteProductSelection >* .panel-default > .panel-heading h3 {
  padding-right: 0;
}

.product-price-container .headline,
.product-price-container .main-price-line,
.product-price-container .bonus-headline,
.product-price-container .bonus-main-price-line {
  background-color: #dbe7f0;
  color: #4d4d4d;
  font-family: Verdana, Arial, sans-serif;
  font-weight: bold;
}

.product-price-container .headline,
.product-price-container .bonus-headline {
  border: 1px solid #ccc;
  border-bottom: 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#97bfdc+0,69a4cc+100 */
  background: #97bfdc; /* Old browsers */
  background: -moz-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #97bfdc), color-stop(100%, #69a4cc)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #97bfdc 0%, #69a4cc 100%); /* IE10+ */
  background: linear-gradient(to bottom, #97bfdc 0%, #69a4cc 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#97bfdc', endColorstr='#69a4cc', GradientType=0); /* IE6-9 */
  color: #fff;
}

.product-price-container > .detail-prices,
.product-price-container > .bonus-prices {
  background-color: #f7fafd;
  border-color: #ccc;
}

.product-price-container .main-price-line,
.product-price-container .bonus-main-price-line {
  border: 1px solid #ccc;
  color: #1644a8;
}

.product-price-container .main-price-line > .big-price {
  font-family: Verdana, Arial, sans-serif;
  font-weight: bold;
}

span.product-branch-icon.power {
  background: rgba(0, 0, 0, 0) url("../../images/de_DE/icon-power.png") no-repeat scroll center center / auto auto;
}

#productSearchProcessForm2,
#productSearchWidget #productSearchForm {

  .form-control {
    padding: 0 8px;
  }

  label {
    margin: 4px 0px 0px 0px;
  }

  .radio-inline {
    margin: 0;
  }

  .radio-inline:nth-child(2n+1) {
    width: 45%;
  }

  .radio-inline:nth-child(2n) {
    width: 53%;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .panel-body {
    padding-top: 10px;
  }

  .radio-inline {
    padding-top: 0 !important;
  }
}

.prettycheckbox label img.check-branch-icon, .prettyradio label img.radio-branch-icon {
  height: auto;
  width: auto;
}

#productSearchWidget #productSearchForm .customer-selection label {
  margin: 7px 0px 0px 0px;
}

#productSearchForm.radio-check-table {
  padding-bottom: 12px;

  label {
    width: 200px;
  }
}

/*Slider*/

#widget-layout-container .panel-body .radio-inline,
#widget-layout-container .panel-body .checkbox-inline {
  margin-bottom: -11px;
  margin-top: 0px;
}

.widget-container.product-calculator div#gas-slider-legend {
  #gas-slider-full-step-1,
  #gas-slider-full-step-2,
  #gas-slider-full-step-3,
  #gas-slider-full-step-4 {
    font-size: 9px;
    margin-top: 6px;
  }
}

.widget-container.product-calculator div.widget-consumption-input-row {
  margin-top: 15px;
}

.scale100 {
  width: 100%;
}

#meterReadingWidgetContent .col-xs-16 {
  padding-left: 0px;

}

#meterReadingWidget img {
  padding-top: 10px;
}

#productSearchMediaSpecificPOWER .slider-horizontal,
#productSearchMediaSpecificWATER .slider-horizontal {
  margin-left: 8.5%;
  width: 83.6% !important;
}

#productSearchMediaSpecificGAS .slider-horizontal {
  margin-left: 91px;
  width: 75.5% !important;
}

#productSearchMediaSpecificTHERMAL .slider-horizontal {
  margin-left: 91px;
  width: 75.5% !important;
}

.waste-slider-full-step-1,
.waste-slider-full-step-2,
.waste-slider-full-step-3,
.waste-slider-full-step-4,
.waste-slider-full-step-5,
.waste-slider-full-step-6,
.waste-slider-full-step-7,
.waste-slider-full-step-8,
.waste-slider-full-step-9,
.waste-slider-full-step-10,
.waste-slider-full-step-11,
.waste-slider-full-step-12 {
  text-align: center;
  float: left;
  width: 9%;
}

.waste-slider-full-step-1 {
  text-align: left;
  width: 5%;
}

.waste-slider-full-step-12 {
  text-align: right;
  width: 5%;
}

.power-slider-full-step-1,
.power-slider-full-step-2,
.power-slider-full-step-3,
.power-slider-full-step-4,
.power-slider-full-step-5,
.power-slider-full-step-6,
.gas-slider-full-step-1,
.gas-slider-full-step-2,
.gas-slider-full-step-3,
.gas-slider-full-step-4,
.gas-slider-full-step-5,
.gas-slider-full-step-6 {
  text-align: center;
}

.highlight {
  color: #fff;
}

.bootstrap-select.form-control button {
  border-color: #7f8080;

  span.caret {
    color: #7f8080;
  }
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text #power-slider-full-step-1 {
  padding-left: 10px;
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text #power-slider-full-step-2,
.widget-container.product-calculator div#power-slider-legend div.widget-formline-text #power-slider-full-step-3 {
  padding-left: 15px;
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text #power-slider-full-step-4 {
  padding-left: 19px;
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text #power-slider-full-step-5 {
  padding-left: 22px;
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text #power-slider-full-step-6 {
  padding-left: 23px;
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text div {
  width: 16.66%;
}

#water-slider-legend div.widget-formline-text div {
  width: 16.66%;
  float: left;
  text-align: center;
}

#thermal-slider-legend div.widget-formline-text div {
  width: 20%;
  float: left;
}

.widget-container.product-calculator div#power-slider-legend p.widget-formline-text,
#water-slider-legend p.widget-formline-text,
#thermal-slider-legend p.widget-formline-text {
  clear: both;
  float: left;
  margin-bottom: 5px;
  margin-top: 5px;
  width: 100%;
}

.widget-container.product-calculator div#power-slider-legend div.widget-formline-text,
#water-slider-legend div.widget-formline-text,
#thermal-slider-legend div.widget-formline-text {
  clear: both;
  float: left;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 100%;
}

div#power-slider-legend .slider-horizontal,
div#water-slider-legend .slider-horizontal {
  margin-top: 6px;
}

#pointOfConsumptionPostalCode {
  padding: 6px 8px,
}

.widget-container div#thermal-slider-legend #thermal-slider-full-step-1 {
  background-image: url("../../images/de_DE/icon-productSlider-gas-cooking.png");
  background-position: 2px 14px;
  background-repeat: no-repeat;
  float: left;
  font-size: 12px;
  height: 36px;
  padding-left: 0;
  padding-top: 30px;
  width: 25%;
  margin-top: 6px;
  font-size: 9px;

}

.widget-container div#thermal-slider-legend #thermal-slider-full-step-2 {
  background-image: url("../../images/de_DE/icon-productSlider-gas-house-small.png");
  background-position: 5px 1px;
  background-repeat: no-repeat;
  float: left;
  font-size: 12px;
  height: 30px;
  padding-left: 12px;
  padding-top: 30px;
  width: 25%;
  margin-top: 6px;
  font-size: 9px;
}

.widget-container div#thermal-slider-legend #thermal-slider-full-step-3 {
  background-image: url("../../images/de_DE/icon-productSlider-gas-house-medium.png");
  background-position: 20px 1px;
  background-repeat: no-repeat;
  float: left;
  font-size: 12px;
  height: 30px;
  padding-left: 22px;
  padding-top: 30px;
  width: 25%;
  margin-top: 6px;
  font-size: 9px;
}

.widget-container div#thermal-slider-legend #thermal-slider-full-step-4 {
  background-image: url("../../images/de_DE/icon-productSlider-gas-house-big.png");
  background-position: 26px 0;
  background-repeat: no-repeat;
  float: left;
  font-size: 12px;
  height: 30px;
  padding-left: 26px;
  padding-top: 30px;
  width: 25%;
  margin-top: 6px;
  font-size: 9px;
}


/*Styles für Kombination von Headmenue und Topmenue*/

.itc-navbar-right .dropdown {
  padding-top: 12px;

  span {
    color: #fff;
  }
}

.customer-selection {
  border-bottom: 1px solid #ccc;
  height: 33px;
  margin-bottom: 2px;
}

.head-menu-postbox-title {
  display: block;
  padding-right: 2px;
}

.top-menu-postbox {
  width: 100px;
}

div#topMenu .homeLinkContainer {
  height: 26px;
  margin-top: 7px;
  margin-right: 17px;
  float: right;
}

.home-top-menu-icon {
  height: 26px;
}

#widgetContractMediaTypeIcon {

  margin-top: 2px;
}

.pagination > li > a, .pagination > li > span {
  &:hover, &:focus {
    font-family: Verdana, Arial, sans-serif;
    font-weight: normal;
  }
}

#postboxTable_info {
  margin-top: 15px;
}

#postboxTable_wrapper .using-glyphicons {
  color: #FF8800;
}

#postboxTable_wrapper .read .using-glyphicons {
  color: #FFCE9A;
}

#deliveryDate .value-label {
  margin-left: 0px;
}

.contractCreationDeliveryDate {
  padding: 8px 0px;
}

div#headMenu > div.pull-right {
  margin-top: 15px;
}

#headMenu.fixed .col-xs-12:first-child {
  display: block;
}

#headMenu.fixed .branchesScroll {
  display: block;
  background: rgba(0, 0, 0, 0) url("../../images/de_DE/SWS_www_piktogramme.png") no-repeat scroll right top / 100% auto;
  float: left;
  height: 40px;
  margin: -7px 10px 0 15px;
  width: 150px;
}

#headMenu.fixed .direktserviceScroll {
  display: block;

  float: left;
  height: 32px;
  margin: 1px 0 0 16px;
  background: rgba(0, 0, 0, 0) url("../../images/de_DE/svg/direktservice_typo.png") no-repeat scroll;
  background-size: 80% auto;
  float: left;
  width: 143px;
}

#topMenu .open > .dropdown-menu > li.sub-menu-head {
  background-color: #3081B8;
  color: #FFF;
  font-family: Verdana, Arial, sans-serif;
  font-weight: normal;
}

.inner.selectpicker > li > a {
  font-size: 12px;
  padding-left: 14px;
}

.productID-white {
  color: #FFF;
}

#advancePaymentUpdateForm {
  .prettycheckbox label img.check-branch-icon {
    margin-top: -5px;
  }

  div.col-xs-16 {
    margin-top: 13px;
  }

  .form-group:first-child {
    margin-top: 10px;
  }

  .highlight {
    color: #4d4d4d;
  }
}

.panel-title a:hover,
.panel-title a:focus {
  color: #FFF;
}

#overview .prettycheckbox label, #overview .prettyradio label {
  margin-top: 9px;
}

#overview .form-group-panel-default .form-group .value-label {
  margin-bottom: 10px;
}

.widget-loading-container {
  padding-top: 15px;
}

#accordion {
  .panel-heading {
    padding: 0px;

    h4 a {
      display: inline-block;
      padding: 9px 15px 10px;
      width: 100%;
    }
  }
}

.pl-20 {
  padding-left: 20px;
}

.swiper-container, .swiper-wrapper, .swiper-slide {
  height: 262px !important;
}

.swiper-slide.meter.gas {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-gas.svg) #ffffff no-repeat;
  background-size: 75%;
  background-position: center 16px;
}

.swiper-slide.meter.power {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-energy.svg) #ffffff no-repeat;
  background-size: 70%;
  background-position: center 31px;
}

.swiper-slide.meter.thermal {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-heating.svg) #ffffff no-repeat;
  background-size: 72%;
  background-position: center 35px;
}

.swiper-slide.meter.water {
  background: url(../../images/de_DE/svg/icon-highlight-bg-branch-water.svg) #ffffff no-repeat;
  background-size: 72%;
  background-position: center 35px;
}

div#sideNav {
  position: fixed;
  right: 0;
  top: 245px;
  width: 32px;
  z-index: 1;

  ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;

    li {

      a {
        display: block;
        width: 32px;
        height: 200px;
        overflow: hidden;
        margin-bottom: 1px;
        border: 1px solid #ff931e;
        color: #4D4D4D;
        font-size: 16px;

        background: #ffbb72; /* Old browsers */
        background: -moz-linear-gradient(left, #ffbb72 0%, #ff931e 100%); /* FF3.6+ */
        background: -webkit-gradient(left, color-stop(0%, #ffbb72), color-stop(100%, #ff931e)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, #ffbb72 0%, #ff931e 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, #ffbb72 0%, #ff931e 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(left, #ffbb72 0%, #ff931e 100%); /* IE10+ */
        background: linear-gradient(to right, #ffbb72 0%, #ff931e 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbb72', endColorstr='#ff931e', GradientType=1); /* IE6-9 */


        > span {
          display: inline-block;
          white-space: nowrap;
          line-height: 12px;

          -moz-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);

          .using-glyphicons {
            font-size: 11px;
            margin-right: 5px;
          }
        }

        &:hover, &:focus {
          background: #FFFFFF none;
          color: #ff931e;

          .using-glyphicons {
            color: #ff931e;
          }
        }
      }

      a.visible-xs {
        height: 145px;

        > span {
          margin-left: -15px;
          margin-top: 65px;
        }

        > span.recruitment {
          margin-left: -47px;
        }
      }

      a.hidden-xs {
        height: 200px;

        > span {
          margin-left: -61px;
          margin-top: 92px;
        }

        > span.recruitment {
          margin-left: -56px;
        }
      }

      a.recruitment {
        margin-top: 10px;
      }
    }
  }
}

