#exampleImageContainer {
    position: relative;
}

.bubble {
    position: absolute;
    z-index: 2;
    
    background-color: #5E7386;
    color: white;
    width: 29px;
    border-radius: 15px;
    top: 13.3%;
    left: 8.5%;
}

.exampleInvoice {
    width: 100%;
    height: 100%;
    z-index: 1;
}

.bubbleText {
    display:block;
    font-size:20px;
    text-align: center;
}