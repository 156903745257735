
.open > .dropdown-menu > li.sub-menu-head {
	font-size: 16px;
	font-family: @std-font-family-bold;
	padding: 3px 20px;	
}


.dropdown-menu .divider {
	margin: 0px;
}

#headMenu a.dropdown-toggle {
	color: #1a3552;
	text-decoration: none !important;

	+ .dropdown-menu {
		margin-top: 10px;
	}
}

#headMenu a#head-menu-postbox-link:hover {
	text-decoration: none;	
} 

img.top-drop-menu-icon {
	margin-top: -3px;
	margin-right: 10px;
}	