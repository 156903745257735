
.nav-tabs {
    border-bottom: 1px solid #96a6b3;
	
	.dropdown-menu {
		border-top: 1px solid #96a6b3 !important;
		
		> .active > a,
		> .active > a:hover,
		> .active > a:focus {
			background-color: #f3f4f6;
			color: @std-color;
		}
	}	
}

.nav-tabs > li > a {
	color: #1a3552;
	font-family: @std-font-family-bold;
	background-color: #FFFFFF;
    border-color: #96a6b3;
}

.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus,
.nav-tabs .open > a,
.nav-tabs .open > a:hover,
.nav-tabs .open > a:focus {
	background-color: transparent;
	border-left-color: #96a6b3;
	border-right-color: #96a6b3;	
	border-top-color: #96a6b3;
	border-bottom-color: #FFFFFF;		
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {   
    border-color: #96a6b3 #96a6b3 transparent;  
	color:  #1a3552;
	font-family: @std-font-family-bold;	
}

.tab-pane {
	border-left: 1px solid #96a6b3;
	border-right: 1px solid #96a6b3;
	border-bottom: 1px solid #96a6b3;
	
	margin-bottom: 15px;
	padding: 15px 15px 0px 15px;
}

.navbar-toggle {
    background-color: #ff8a16;
}	

.navbar > .container-fluid .navbar-brand {
    margin-left: 0px;
	
	&:hover,&:focus {
		background-color: transparent;
	}
}

footer .navbar-nav {
	margin: 0px;
}	
